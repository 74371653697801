/* eslint-disable import/no-unresolved */
/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { useSelector, useDispatch } from 'react-redux';
import DataGrid, { Column } from 'devextreme-react/data-grid';
import {
  setType,
  setNormalized,
  setBusinessUnit,
  setClickOkSearch
} from 'store/modules/normalizer/actions';

import { useStyles } from './styles';

const normalizeds = [
  { normalized: 'All', code: 'all' },
  { normalized: 'Yes', code: 'true' },
  { normalized: 'No', code: 'false' }
];

const NormalizerFilter = () => {
  const dispatch = useDispatch();
  const classes = useStyles();
  const { type, normalized, dataType, dataUnit } = useSelector(
    state => state.normalizer
  );

  return (
    <div>
      <DataGrid
        height={200}
        width={400}
        style={{ marginBottom: 20 }}
        className={classes.datagrid}
        rowAlternationEnabled
        dataSource={dataType && dataType.lines}
        selection={{ mode: 'single' }}
        onSelectionChanged={({ selectedRowsData }) => (
          dispatch(setType(selectedRowsData[0].code)),
          dispatch(setClickOkSearch(true))
        )}
      >
        <Column
          width={70}
          alignment="center"
          cellRender={({ data }) => (
            <input type="radio" value={type} checked={type === data.code} />
          )}
        />
        <Column caption="Type" dataField="name" alignment="left" />
      </DataGrid>
      <DataGrid
        className={classes.datagrid}
        width={400}
        height={130}
        style={{ marginBottom: 20 }}
        rowAlternationEnabled
        dataSource={normalizeds}
        selection={{ mode: 'single' }}
        onSelectionChanged={({ selectedRowsData }) => (
          dispatch(setNormalized(selectedRowsData[0].code)),
          dispatch(setClickOkSearch(true))
        )}
      >
        <Column
          width={70}
          alignment="center"
          cellRender={({ data }) => (
            <input
              type="radio"
              value={normalized}
              checked={normalized === data.code}
            />
          )}
        />
        <Column dataField="normalized" alignment="left" />
      </DataGrid>
      <DataGrid
        className={classes.datagridB}
        width={400}
        style={{ marginBottom: 20 }}
        rowAlternationEnabled
        dataSource={dataUnit && dataUnit.lines}
        selection={{ mode: 'multiple' }}
        onSelectionChanged={({ selectedRowsData }) => (
          dispatch(setBusinessUnit(selectedRowsData)),
          dispatch(setClickOkSearch(true))
        )}
      >
        <Column dataField="id" alignment="left" visible={false} />
        <Column caption="Business Unit" dataField="name" alignment="left" />
      </DataGrid>
    </div>
  );
};

export default NormalizerFilter;
