import { makeStyles } from '@material-ui/core/styles';

export const useStyles = makeStyles(theme => ({
  container: {
    width: '100%',

    overflow: 'hidden'
  },
  leftContent: {
    width: '100%'
  },
  filter: {
    display: 'flex',
    width: '100%'
  },
  datagrid: {
    marginRight: '15px',
    '& table tbody td': {
      cursor: 'pointer'
    }
  },
  datagridB: {
    height: '30vh',
    marginRight: '15px',
    '& table tbody td': {
      cursor: 'pointer'
    }
  },
  searchBox: {
    width: '30vw',

    marginBottom: '5px',
    alignSelf: 'flex-start',

    justifyContent: 'flex-start',
    '& input': {
      width: '90%',
      height: '36px',
      fontSize: '14px',
      border: `1px solid #E0E0E0`,
      borderRadius: '5px',
      outline: 0,
      transition: 'all ease .4s',

      backgroundColor: '#fffff',
      padding: '0 15px',
      alignItems: 'center',
      '&:focus': {
        border: `1px solid #FFCD00 `,
        color: '#000'
      }
    },
    '& button': {
      cursor: 'pointer',

      border: 'none',
      color: '#fff',
      marginLeft: '5px',
      backgroundColor: 'rgb(0, 159, 227)',
      width: '50px',
      height: '35px',
      borderRadius: '5px'
    }
  },
  grid: {
    display: 'flex',
    flexDirection: 'column'
  },
  rightContent: {
    width: '50%'
  },
  buttons: {
    marginTop: '5px',
    display: 'flex',
    justifyContent: 'space-between',

    '& button': {
      cursor: 'pointer',
      border: 'none',
      color: '#fff',
      marginLeft: '5px',
      backgroundColor: 'rgb(0, 159, 227)',
      width: '150px',
      height: '35px',
      borderRadius: '5px'
    }
  },
  modalContent: {
    width: '600px',
    '& div': {
      '& input, select': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginRight: '30px',
        marginBottom: '30px',
        backgroundColor: '#fffff',
        padding: '0 15px',
        alignItems: 'center',
        '&:focus': {
          border: `1px solid #FFCD00 `,
          color: '#000'
        },
        '&::placeholder': {
          color: '#E0E0E0'
        }
      }
    }
  },
  inputF: {
    '& div': {
      '& input, select': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginRight: '30px',
        marginBottom: '5px',
        backgroundColor: '#fffff',
        padding: '0 15px',
        alignItems: 'center',
        '&:focus': {
          border: `1px solid #FFCD00 `,
          color: '#000'
        },
        '&::placeholder': {
          color: '#E0E0E0'
        }
      }
    }
  },
  compl: {
    width: '300px',
    // height: '20px',
    marginBottom: '10px'
  }
}));
