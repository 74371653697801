// eslint-disable-next-line import/no-unresolved
import axios from 'axios';
import * as actionTypes from '../types';

export function setType(value) {
  return {
    type: actionTypes.SET_TYPE,
    payload: { value }
  };
}

export function setNormalized(value) {
  return {
    type: actionTypes.SET_NORMALIZED,
    payload: { value }
  };
}

const getTypeSuccess = data => {
  return {
    type: actionTypes.GET_DATA_TYPE,
    data
  };
};

export const getType = url => {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getTypeSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const getMainSuccess = data => {
  return {
    type: actionTypes.GET_DATA_MAIN,
    data
  };
};

export const getMain = url => {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getMainSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const getNormSuccess = data => {
  return {
    type: actionTypes.GET_DATA_NORM,
    data
  };
};

export const getNormMergedSuccess = (data, merge) => {
  return {
    type: actionTypes.GET_DATA_NORM_MERGED,
    payload: { data, merge }
  };
};

export const getNorm = url => {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getNormSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
export const getNormMerged = (url, merge) => {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getNormMergedSuccess(response.data, merge));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const postTypeSuccess = response => {
  return {
    type: actionTypes.POST_DATA_TYPE,
    response
  };
};

export const postType = (url, obj) => {
  return dispatch => {
    axios
      .post(url, obj)
      .then(response => {
        dispatch(postTypeSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const postMainSuccess = response => {
  return {
    type: actionTypes.POST_DATA_MAIN,
    response
  };
};

export const postMain = (url, obj) => {
  return dispatch => {
    axios
      .post(url, obj)
      .then(response => {
        dispatch(postMainSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const putMainSuccess = response => {
  return {
    type: actionTypes.PUT_DATA_MAIN,
    response
  };
};

export const putMain = (url, obj) => {
  return dispatch => {
    axios
      .put(url, obj)
      .then(response => {
        dispatch(putMainSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const postNormSuccess = response => {
  return {
    type: actionTypes.POST_DATA_NORM,
    response
  };
};

export const postNorm = (url, obj) => {
  return dispatch => {
    axios
      .post(url, obj)
      .then(response => {
        dispatch(postNormSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

export const setBusinessUnit = data => {
  return {
    type: actionTypes.SET_BUSINESS_UNIT,
    data
  };
};

export const updateDataGrid = (data, newValue, idMain, normalized) => {
  return {
    type: actionTypes.UPDATE_DATA_GRID,
    payload: { data, newValue, idMain, normalized }
  };
};

export const setClickOkSearch = data => {
  return {
    type: actionTypes.SET_CLICK_OK,
    data
  };
};

const getDataUnitSuccess = data => {
  return {
    type: actionTypes.GET_DATA_UNIT,
    data
  };
};

export const getDataUnit = url => {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getDataUnitSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};
