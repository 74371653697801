/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SET_SQL,
  POST_JSON,
  SET_CSS,
  SET_JS,
  SET_HEAD,
  SET_BODY,
  SET_PARAMS,
  PUT_PDF,
  GET_PDF,
  GET_FOLDER_PDF,
  SET_OPEN_MENU_STUDIO,
  PUT_REPORTS,
  PUT_REPORTS_FOLDERS,
  POST_REPORTS_FOLDERS,
  DEL_FOLDER,
  POST_REPORTS,
  SET_PDF_OPTIONS,
  SET_TITLE,
  SET_UNSAVE,
  SET_USER_PROPS,
  SET_READ_ONLY_ACE,
  PUT_REPORTS_VERSION,
  POST_REPORTS_VERSION,
  GET_PDF_VERSION,
  SET_CURRENT_VERSION,
  GET_SNIPPETS,
  GET_DATABASE,
  IS_LOADING,
  SET_READ_ONLY_MODE,
  IS_LOADING_TAB,
  SET_RED_PARAMS
} from '../types';

const INITIAL_STATE = {
  title: 'Report',
  readOnlyAce: true,
  readOnlyMode: true,
  userPropsValue: {},
  redSql: '',
  redJson: '',
  redCss: '',
  redJS: '',
  redHead: [],
  redBody: '',
  redParams: [],
  pdfTemplate: null,
  pdf: null,
  folderPdf: null,
  params: null,
  openMenuStudio: false,
  report: null,
  folders: null,
  pdfOptions: {
    format: 'A4',
    height: '0',
    heightUn: 'px',
    landscape: false,
    margin: {
      bottom: '0',
      bottomUn: 'px',
      left: '0',
      leftUn: 'px',
      right: '0',
      rightUn: 'px',
      top: '0',
      topUn: 'px'
    },
    path: '',
    printBackground: true,
    scale: 0,
    width: '0',
    widthUn: 'px'
  },
  unSave: false,
  snippet: null,
  database: null,
  loading: false,
  loadingTab: false
};

const studio = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case SET_UNSAVE: {
        draft.unSave = action.payload.unSave;
        break;
      }
      case SET_CURRENT_VERSION: {
        draft.currentVersion = action.payload.currentVersion;
        break;
      }
      case SET_TITLE: {
        draft.title = action.payload.title;
        break;
      }
      case SET_READ_ONLY_ACE: {
        draft.readOnlyAce = action.payload.readOnlyAce;
        break;
      }
      case SET_READ_ONLY_MODE: {
        draft.readOnlyMode = action.payload.readOnlyMode;
        break;
      }
      case SET_SQL: {
        draft.redSql = action.payload.redSql;
        break;
      }
      case POST_JSON: {
        draft.redJson = action.payload.json;
        break;
      }
      case IS_LOADING: {
        draft.loading = action.payload.loading;
        break;
      }
      case IS_LOADING_TAB: {
        draft.loadingTab = action.payload.loadingTab;
        break;
      }
      case SET_CSS: {
        draft.redCss = action.payload.redCss;
        break;
      }
      case SET_JS: {
        draft.redJS = action.payload.redJS;
        break;
      }
      case SET_HEAD: {
        draft.redHead = action.payload.redHead;
        break;
      }
      case SET_BODY: {
        draft.redBody = action.payload.redBody;
        break;
      }
      case SET_RED_PARAMS: {
        draft.redParams = action.payload.redParams;
        break;
      }
      case SET_OPEN_MENU_STUDIO: {
        draft.openMenuStudio = action.payload.open;
        break;
      }
      case SET_PDF_OPTIONS: {
        draft.pdfOptions = action.payload.pdfOptions;
        break;
      }
      case SET_PARAMS: {
        draft.params = action.payload.params;
        break;
      }
      case SET_USER_PROPS: {
        draft.userPropsValue = action.payload.userPropsValue;
        break;
      }
      case PUT_PDF: {
        draft.pdfTemplate = action.payload.pdfTemplate;
        break;
      }
      case PUT_REPORTS: {
        draft.report = action.payload.report;
        break;
      }

      case POST_REPORTS: {
        draft.report = action.payload.report;
        break;
      }

      case PUT_REPORTS_FOLDERS: {
        draft.folders = action.payload.folders;
        break;
      }
      case POST_REPORTS_FOLDERS: {
        draft.folders = action.payload.folders;
        break;
      }
      case GET_PDF: {
        draft.pdf = action.payload.pdf;
        break;
      }
      case GET_DATABASE: {
        draft.database = action.payload.database;
        break;
      }
      case DEL_FOLDER: {
        // draft.pdf = action.payload.pdf;
        break;
      }
      case GET_FOLDER_PDF: {
        draft.folderPdf = action.payload.folderPdf;
        break;
      }

      case PUT_REPORTS_VERSION: {
        draft.reportVersion = action.payload.reportVersion;
        break;
      }
      case POST_REPORTS_VERSION: {
        draft.reportVersion = action.payload.reportVersion;
        break;
      }
      case GET_PDF_VERSION: {
        draft.pdfVersion = action.payload.pdfVersion;
        break;
      }
      case GET_SNIPPETS: {
        draft.snippet = action.payload.snippet;
        break;
      }
      default:
        break;
    }
  });
};

export default studio;
