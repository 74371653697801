/* eslint-disable no-console */
/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import SelectBox from 'devextreme-react/select-box';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { Button } from 'devextreme-react/button';
import { NumberBox } from 'devextreme-react/number-box';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import {
  getPDF,
  setSql,
  setHead,
  setJS,
  setCss,
  setBody,
  getFolderPDF,
  setParams,
  postReportsFolders,
  putReports,
  postReports,
  delFolder,
  putReportsFolders,
  setPdfOptions,
  setTitle,
  setUnSave,
  setUserPropsValue,
  getPDFVersion,
  setCurrentVersion,
  setReadOnlyMode,
  setLoadingTab,
  setRedParams
} from 'store/modules/studio/actions';
import DataGrid, {
  Column,
  Lookup,
  Editing,
  MasterDetail
} from 'devextreme-react/data-grid';
import { makeStyles } from '@material-ui/core/styles';
import FolderIcon from '@material-ui/icons/Folder';
import FileCopyIcon from '@material-ui/icons/FileCopy';
import {
  TextField,
  Divider,
  IconButton,
  TextareaAutosize
} from '@material-ui/core';
import { KeyboardDatePicker } from '@material-ui/pickers';
import {
  AiFillFolderAdd,
  AiFillFileAdd,
  AiOutlineCloudUpload,
  AiFillEdit,
  AiOutlineCloudDownload,
  AiFillDelete
} from 'react-icons/ai';
import { MdAddCircleOutline } from 'react-icons/md';
import { FaFolderOpen } from 'react-icons/fa';
import { Modal } from 'componentes';
import { IoLogoCss3 } from 'react-icons/io';
import { TreeView, TreeItem } from '@material-ui/lab';
import './style.css';
import notify from 'devextreme/ui/notify';
import compareObjects from 'utils/compareObjects';

const useStyles = makeStyles(theme => ({
  buttonExpand: {
    width: '80px',
    height: '30px'
  },
  root: {
    display: 'flex',
    padding: 10
  },
  folders: {
    height: 216,
    flexGrow: 1,
    maxWidth: 300
  },
  buttonCancel: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#F53623',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#A63623',
      opacity: '10'
    }
  },
  buttonSave: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#FFCD00',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#FFCD50',
      opacity: '10'
    }
  },
  form: {
    backgroundColor: '#fff',
    width: '400px',

    borderRadius: '6px',

    '& h3': {
      textAlign: 'center',
      marginBottom: '10px'
    },

    '& button': {
      cursor: 'pointer',
      height: '36px',
      margin: theme.spacing(1, 0, 1),
      backgroundColor: '#FFCD00',
      color: '#F0F0F0',
      borderRadius: '5px',
      border: `1px solid #F0F0F0`,
      outline: 0,
      '&:hover': {
        backgroundColor: '#FFCD00',
        opacity: '10'
      }
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',

      '& input': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #F0F0F0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginBottom: '15px',
        backgroundColor: '#fff',

        '&:focus': {
          border: `1px solid #FFCD00`,
          color: '#000'
        }
      }
    }
  },
  list: {
    display: 'flex',

    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  },
  reportItem: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  },

  reportItemTemplate: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& a': {
      display: 'inline-block'
    },

    '& svg': {
      fontSize: '16px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  }
}));

let abertura = false;

const EnumIdStep = [
  {
    id: 1,
    name: 'Requesting'
  },
  {
    id: 2,
    name: 'Detailing'
  },
  {
    id: 3,
    name: 'Budgeting'
  },
  {
    id: 4,
    name: 'Approval'
  },
  {
    id: 5,
    name: 'Development'
  },
  {
    id: 6,
    name: 'Test'
  },
  {
    id: 7,
    name: 'Validation'
  },
  {
    id: 8,
    name: 'Production'
  }
];

const ParamsFilter = () => {
  const { studio, auth } = useSelector(state => state);
  const { pdfOptions } = studio;
  const dispatch = useDispatch();
  const classes = useStyles();

  const [selectedId, setSelectedId] = useState(0);
  const [selectedIdCurr, setSelectedIdCurr] = useState(0);
  const [inputError, setInputError] = useState('');
  const [modalIsOpen, setModalIsOpen] = useState({ state: false, title: '' });
  const [modalIsOpenUP, setModalIsOpenUP] = useState(false);
  const [expandTreeview, setExpandTreeView] = useState([]);
  const [modalParams, setModalParams] = useState({
    state: false,
    params: {
      url: '',
      type: '',
      title: '',
      options: []
    }
  });
  const [rFolder, setRFolder] = useState({
    id: 0,
    idFolder: null,
    name: 'New',
    enabled: true,
    edit: false,
    file: false
  });
  const [rInput, setRInput] = useState({
    name: '',
    value: ''
  });

  async function getTemplate(shortId, currentVersion) {
    try {
      const id = shortId.slice(1, shortId.length);
      dispatch(setLoadingTab(true));
      setSelectedId(id);
      dispatch(setCurrentVersion(currentVersion));
      setSelectedIdCurr(currentVersion);
      await dispatch(
        getPDFVersion(
          `${auth.apiPath}/safe/reports/${id}/versions?enabled=true`
        )
      );
      await dispatch(
        getPDF(`${auth.apiPath}/safe/reports/${id}/versions/${currentVersion}`)
      );
    } catch (err) {
      setInputError(err);
    }
  }

  useEffect(() => {
    if (abertura && inputError) {
      notify(inputError, 'error', 2000);
    }
    abertura = true;
  }, [inputError]);

  useEffect(() => {
    console.log('1');
    try {
      if (studio.pdf && studio.pdf.lines && !studio.unSave && abertura) {
        if (compareObjects(studio.pdf.lines.sourceParams, studio.redParams))
          dispatch(setRedParams(studio.pdf.lines.sourceParams));
        if (compareObjects(studio.pdf.lines.sourceBody, studio.redBody))
          dispatch(setBody(studio.pdf.lines.sourceBody));
        if (compareObjects(studio.pdf.lines.sourceJs, studio.redJS))
          dispatch(setJS(studio.pdf.lines.sourceJs));
        if (compareObjects(studio.pdf.lines.sourceCss, studio.redCss))
          dispatch(setCss(studio.pdf.lines.sourceCss));
        if (compareObjects(studio.pdf.lines.sourceSql, studio.redSql))
          dispatch(setSql(studio.pdf.lines.sourceSql));
        if (compareObjects(studio.pdf.lines.sourceHead, studio.redHead))
          dispatch(setHead(studio.pdf.lines.sourceHead));

        dispatch(setCurrentVersion(studio.pdf.lines.version));
        if (studio.pdf.lines.pdfOptions) {
          dispatch(
            setPdfOptions({
              format: studio.pdf.lines.pdfOptions.format
                ? studio.pdf.lines.pdfOptions.format
                : null,
              height: studio.pdf.lines.pdfOptions.height
                ? studio.pdf.lines.pdfOptions.height.substring(
                    0,
                    studio.pdf.lines.pdfOptions.height.length - 2
                  )
                : pdfOptions.height,
              heightUn: studio.pdf.lines.pdfOptions.height
                ? studio.pdf.lines.pdfOptions.height.substring(
                    studio.pdf.lines.pdfOptions.height.length - 2,
                    studio.pdf.lines.pdfOptions.height.length
                  )
                : pdfOptions.heightUn,
              landscape: studio.pdf.lines.pdfOptions.landscape
                ? studio.pdf.lines.pdfOptions.landscape
                : pdfOptions.landscape,
              margin: {
                bottom:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.bottom
                    ? studio.pdf.lines.pdfOptions.margin.bottom.substring(
                        0,
                        studio.pdf.lines.pdfOptions.margin.bottom.length - 2
                      )
                    : pdfOptions.margin.bottom,
                bottomUn:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.bottom
                    ? studio.pdf.lines.pdfOptions.margin.bottom.substring(
                        studio.pdf.lines.pdfOptions.margin.bottom.length - 2,
                        studio.pdf.lines.pdfOptions.margin.bottom.length
                      )
                    : pdfOptions.margin.bottomUn,
                left:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.left
                    ? studio.pdf.lines.pdfOptions.margin.left.substring(
                        0,
                        studio.pdf.lines.pdfOptions.margin.left.length - 2
                      )
                    : pdfOptions.margin.left,
                leftUn:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.left
                    ? studio.pdf.lines.pdfOptions.margin.left.substring(
                        studio.pdf.lines.pdfOptions.margin.left.length - 2,
                        studio.pdf.lines.pdfOptions.margin.left.length
                      )
                    : pdfOptions.margin.leftUn,
                right:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.right
                    ? studio.pdf.lines.pdfOptions.margin.right.substring(
                        0,
                        studio.pdf.lines.pdfOptions.margin.right.length - 2
                      )
                    : pdfOptions.margin.right,
                rightUn:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.right
                    ? studio.pdf.lines.pdfOptions.margin.right.substring(
                        studio.pdf.lines.pdfOptions.margin.right.length - 2,
                        studio.pdf.lines.pdfOptions.margin.right.length
                      )
                    : pdfOptions.margin.rightUn,
                top:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.top
                    ? studio.pdf.lines.pdfOptions.margin.top.substring(
                        0,
                        studio.pdf.lines.pdfOptions.margin.top.length - 2
                      )
                    : pdfOptions.margin.top,
                topUn:
                  studio.pdf.lines.pdfOptions.margin &&
                  studio.pdf.lines.pdfOptions.margin.top
                    ? studio.pdf.lines.pdfOptions.margin.top.substring(
                        studio.pdf.lines.pdfOptions.margin.top.length - 2,
                        studio.pdf.lines.pdfOptions.margin.top.length
                      )
                    : pdfOptions.margin.topUn
              },
              path: studio.pdf.lines.pdfOptions.path
                ? studio.pdf.lines.pdfOptions.path
                : pdfOptions.path,
              printBackground: studio.pdf.lines.pdfOptions.printBackground
                ? studio.pdf.lines.pdfOptions.printBackground
                : pdfOptions.printBackground,
              scale: studio.pdf.lines.pdfOptions.scale
                ? studio.pdf.lines.pdfOptions.scale
                : pdfOptions.scale,
              width: studio.pdf.lines.pdfOptions.width
                ? studio.pdf.lines.pdfOptions.width.substring(
                    0,
                    studio.pdf.lines.pdfOptions.width.length - 2
                  )
                : pdfOptions.width,
              widthUn: studio.pdf.lines.pdfOptions.width
                ? studio.pdf.lines.pdfOptions.width.substring(
                    studio.pdf.lines.pdfOptions.width.length - 2,
                    studio.pdf.lines.pdfOptions.width.length
                  )
                : pdfOptions.widthUn
            })
          );
        }
        dispatch(setLoadingTab(false));
      }
    } catch (err) {
      setInputError(err);
    }
  }, [studio.pdf && studio.pdf.lines]);

  useEffect(() => {
    console.log('2');
    if (studio.folderPdf) {
      setTimeout(() => {
        const newFolderPdf = studio.folderPdf.lines.map(item => item.id);
        setExpandTreeView(newFolderPdf);
      }, 2000);
    }
  }, [studio.folderPdf && studio.folderPdf.lines]);

  const setValueParams = (type, value) => {
    try {
      if (studio.redParams && studio.redParams.length > 0) {
        const paramsGet = {};
        studio.redParams.map(v => {
          if (type === v.type) {
            paramsGet[v.name] = value;
            return paramsGet;
          }
          const val = document.getElementById(`js_params_${v.name}`).value;
          paramsGet[v.name] = val;
          return paramsGet;
        });

        dispatch(setParams(paramsGet));
        return paramsGet;
      }
    } catch (err) {
      setInputError(err);
    }
  };

  const setValueUserPropsValue = () => {
    try {
      if (studio.pdf && studio.pdf.lines.userProps) {
        // const paramsGet = {};
        // studio.pdf.lines.userProps.map(v => {
        //   const val = document.getElementById(`js_userpropsv_${v.name}`).value;
        //   paramsGet[v.value] = val;
        //   return paramsGet;
        // });
        // dispatch(setUserPropsValue(paramsGet));
        // return paramsGet;
      }
    } catch (err) {
      setInputError(err);
    }
  };

  const newOrEditFolder = async () => {
    try {
      if (rFolder.edit) {
        await dispatch(
          putReportsFolders(
            `${auth.apiPath}/safe/reports/folders/${selectedId}`,
            {
              name: rFolder.name
            }
          )
        );
      } else {
        await dispatch(
          postReportsFolders(`${auth.apiPath}/safe/reports/folders`, {
            idFolder: rFolder.idFolder,
            name: rFolder.name,
            enabled: true
          })
        );
      }
      await setModalIsOpen({
        ...modalIsOpen,
        state: false
      });
      await dispatch(getFolderPDF(`${auth.apiPath}/safe/reports/folders`));
    } catch (err) {
      setInputError(err);
    }
  };

  const newOrEditFile = async () => {
    try {
      if (rFolder.edit) {
        await dispatch(
          putReports(`${auth.apiPath}/safe/reports/folders/${selectedId}`, {
            name: rFolder.name
          })
        );
      } else {
        await dispatch(
          postReports(`${auth.apiPath}/safe/reports`, {
            idFolder: rFolder.idFolder,
            name: rFolder.name
          })
        );
      }
      await setModalIsOpen({
        ...modalIsOpen,
        state: false
      });

      await dispatch(getFolderPDF(`${auth.apiPath}/safe/reports/folders`));
    } catch (err) {
      setInputError(err);
    }
  };

  const renderFormInput = () => {
    try {
      if (studio.redParams && studio.redParams.length > 0) {
        return studio.redParams.map((v, index) => {
          return (
            <div
              style={{
                display: 'flex',
                justifyContent: 'space-between',
                alignItems: 'center',
                width: 380,
                marginBottom: 5
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'space-between',
                  alignItems: 'center'
                }}
              >
                <p>{v.title}</p>
              </div>
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  alignItems: 'center'
                }}
              >
                <IconButton
                  style={{ marginLeft: 10 }}
                  onClick={() =>
                    setModalParams({
                      state: true,
                      params: v
                    })
                  }
                >
                  <AiFillEdit style={{ height: 15, width: 15 }} />
                </IconButton>

                {v.type === 'input-text' ? (
                  <TextField
                    style={{ width: 250 }}
                    id={`js_params_${v.name}`}
                    variant="outlined"
                    value={
                      studio.params &&
                      Object.values(studio.params).filter((b, i) => {
                        if (i === index) {
                          return b;
                        }
                      })
                    }
                    onChange={setValueParams}
                  />
                ) : v.type === 'input-number' ? (
                  <TextField
                    style={{ width: 250 }}
                    type="number"
                    id={`js_params_${v.name}`}
                    variant="outlined"
                    value={
                      studio.params &&
                      Object.values(studio.params).filter((b, i) => {
                        if (i === index) {
                          return b;
                        }
                      })
                    }
                    onChange={setValueParams}
                  />
                ) : v.type === 'data' ? (
                  <input
                    style={{
                      height: 38,
                      width: 250,
                      borderRadius: 5,
                      border: '0.5px solid #CACACA'
                    }}
                    type="date"
                    id={`js_params_${v.name}`}
                    minLength="4"
                    maxLength="8"
                    size="10"
                  />
                ) : v.type === 'input-file' ? (
                  <TextField
                    style={{ width: 250 }}
                    id={`js_params_${v.name}`}
                    variant="outlined"
                    value={
                      studio.params &&
                      Object.values(studio.params).filter((b, i) => {
                        if (i === index) {
                          return b;
                        }
                      })
                    }
                    onChange={setValueParams}
                  />
                ) : v.type === 'select' ? (
                  <SelectBox
                    style={{ width: 250 }}
                    items={v.options}
                    defaultValue={0}
                    id={`js_params_${v.name}`}
                    onValueChanged={e => setValueParams('select', e.value)}
                  />
                ) : (
                  <TextField
                    style={{ width: 250 }}
                    id={`js_params_${v.name}`}
                    variant="outlined"
                    value={
                      studio.params &&
                      Object.values(studio.params).filter((b, i) => {
                        if (i === index) {
                          return b;
                        }
                      })
                    }
                    onChange={setValueParams}
                  />
                )}
              </div>
            </div>
          );
        });
      }
    } catch (err) {
      setInputError(err);
    }
  };

  const renderFormInputProperties = () => {
    try {
      if (studio.pdf && studio.pdf.lines && studio.pdf.lines.userProps) {
        const newUserProps = Object.entries(studio.pdf.lines.userProps);
        return newUserProps.map(v => (
          <div
            style={{
              display: 'flex',
              justifyContent: 'space-between',
              alignItems: 'center',
              width: 380,
              marginBottom: 5
            }}
          >
            <TextField
              style={{ width: 150 }}
              id={`js_userprops_${v[0]}`}
              value={v[0]}
              // onChange={setValueUserPropsValue}
            />
            <TextField
              style={{ width: 220 }}
              id={`js_userpropsv_${v[1]}`}
              variant="outlined"
              value={v[1]}
              onChange={setValueUserPropsValue}
            />
          </div>
        ));
      }
    } catch (err) {
      setInputError(err);
    }
  };

  const addUserPropsValue = async () => {
    try {
      await setModalIsOpenUP(false);

      const obj = {
        userProps: {
          ...studio.pdf.lines.userProps,
          [rInput.name]: rInput.value
        }
      };

      await dispatch(
        putReports(
          `${auth.apiPath}/safe/reports/${studio.pdf.lines.idReport}/versions/${studio.currentVersion}`,
          obj
        )
      );

      await setRInput({
        name: '',
        type: ''
      });

      await dispatch(
        getPDF(
          `${auth.apiPath}/safe/reports/${selectedId}/versions/${studio.currentVersion}`
        )
      );

      notify('Dados salvos', 'success', 2000);
    } catch (err) {
      setInputError(err);
    }
  };

  const alterTypeParams = async () => {
    try {
      const oldObj = studio.redParams.filter(
        v => v.name !== modalParams.params.name
      );
      const obj = {
        sourceParams: [...oldObj, modalParams.params]
      };

      await dispatch(
        putReports(
          `${auth.apiPath}/safe/reports/${studio.pdf.lines.idReport}/versions/${selectedIdCurr}`,
          obj
        )
      );

      await dispatch(
        getPDF(
          `${auth.apiPath}/safe/reports/${studio.pdf.lines.idReport}/versions/${selectedIdCurr}`
        )
      );

      notify('Dados salvos', 'success', 2000);
    } catch (err) {
      setInputError(err);
    }
  };

  const downloadFile = async () => {
    try {
      if (studio.pdf && studio.pdf.lines) {
        const datas = new Date().toISOString().replaceAll(':', '-');
        const nameFile = `${studio.pdf.lines.name}${datas}.arp`;
        const fileData = JSON.stringify({ content: studio.pdf.lines });
        const blob = new Blob([fileData], { type: 'text/plain' });
        const url = URL.createObjectURL(blob);
        const link = document.createElement('a');
        link.download = nameFile;
        link.href = url;
        link.click();
      }
    } catch (err) {
      setInputError(err);
    }
  };

  const updaloadFile = async e => {
    try {
      e.preventDefault();
      const reader = new FileReader();
      reader.onload = async s => {
        const text = s.target.result;
        const obj = JSON.parse(text);
        const newObj = {
          ...obj.content,
          idFolder: selectedId
        };
        await dispatch(postReports(`${auth.apiPath}/safe/reports`, newObj));
      };
      reader.readAsText(e.target.files[0]);
    } catch (err) {
      setInputError(err);
    }
  };

  const handleClone = async e => {
    try {
      await dispatch(
        postReports(
          `${auth.apiPath}/safe/reports/${e.row.data.idReport}/versions`
        )
      );

      await dispatch(
        getPDFVersion(
          `${auth.apiPath}/safe/reports/${e.row.data.idReport}/versions?enabled=true`
        )
      );
    } catch (err) {
      setInputError(err);
    }
  };

  const handlePublic = async e => {
    try {
      const obj = {
        idStep: 8
      };

      await dispatch(
        putReports(
          `${auth.apiPath}/safe/reports/${e.row.data.idReport}/versions/${e.row.data.version}`,
          obj
        )
      );
    } catch (err) {
      setInputError(err);
    }
  };

  const handleDeleteReport = async id => {
    try {
      dispatch(setLoadingTab(true));

      await dispatch(
        putReports(`${auth.apiPath}/safe/reports/${id}`, {
          enabled: false
        })
      );
      await dispatch(getFolderPDF(`${auth.apiPath}/safe/reports/folders`));
      await dispatch(setRedParams([]));
      await dispatch(setBody(''));
      await dispatch(setJS(''));
      await dispatch(setCss(''));
      await dispatch(setSql(''));
      await dispatch(setHead([]));
      await dispatch(setCurrentVersion(0));
      await dispatch(
        setPdfOptions({
          format: 'A4',
          height: '0',
          heightUn: 'px',
          landscape: false,
          margin: {
            bottom: '0',
            bottomUn: 'px',
            left: '0',
            leftUn: 'px',
            right: '0',
            rightUn: 'px',
            top: '0',
            topUn: 'px'
          },
          path: '',
          printBackground: true,
          scale: 0,
          width: '0',
          widthUn: 'px'
        })
      );

      dispatch(setLoadingTab(false));
    } catch (err) {
      setInputError(err);
    }
  };

  const handleDeleteVersion = async e => {
    await dispatch(
      putReports(
        `${auth.apiPath}/safe/reports/${e.data.idReport}/versions/${e.data.version}`,
        {
          enabled: false
        }
      )
    );
    await dispatch(
      getPDFVersion(
        `${auth.apiPath}/safe/reports/${e.data.idReport}/versions?enabled=true`
      )
    );
  };

  const handleFocus = event => event.target.select();

  try {
    if (studio.folderPdf && studio.folderPdf.lines) {
      return (
        <>
          <div
            style={{
              width: '380px',
              marginTop: '10px',
              padding: 10
            }}
          >
            <TreeView
              style={{
                minHeight: '38vh',
                height: '100%',
                flexGrow: 1,
                maxWidth: 400
              }}
              defaultCollapseIcon={<ArrowDropDownIcon />}
              defaultExpandIcon={<ArrowRightIcon />}
              defaultEndIcon={<div style={{ width: 24 }} />}
              defaultExpanded={[0]}
              // expanded={expandTreeview}
            >
              <TreeItem
                style={{ padding: 5 }}
                nodeId={0}
                label={
                  <div style={{ display: 'flex', alignItems: 'center' }}>
                    <a className={classes.reportItemTemplate}>
                      <FaFolderOpen
                        color="inherit"
                        style={{ marginRight: 5 }}
                      />

                      <p> Root </p>
                      <span style={{ height: 20, marginTop: -5 }}>
                        <IconButton
                          onClick={async () => {
                            await setModalIsOpen({
                              state: true,
                              title: 'New Folder'
                            });
                            await setRFolder({
                              id: 0,
                              idFolder: null,
                              name: '',
                              enabled: true,
                              edit: false,
                              file: false
                            });
                          }}
                        >
                          <AiFillFolderAdd />
                        </IconButton>
                      </span>
                    </a>
                  </div>
                }
              >
                {studio.folderPdf.lines.map(v => (
                  <TreeItem
                    style={{ padding: 5 }}
                    nodeId={v.id}
                    label={
                      <div style={{ display: 'flex', alignItems: 'center' }}>
                        <a className={classes.reportItemTemplate}>
                          {v.type === 'folder' ? (
                            <>
                              <a style={{ display: 'flex' }}>
                                <FolderIcon
                                  color="inherit"
                                  style={{ marginRight: 5 }}
                                />

                                <p> {v.name} </p>
                              </a>
                              <span style={{ height: 20, marginTop: -5 }}>
                                <IconButton
                                  onClick={async () => {
                                    const id = v.id.slice(1, v.id.length);
                                    await setModalIsOpen({
                                      state: true,
                                      title: 'New Folder'
                                    });
                                    await setRFolder({
                                      id,
                                      idFolder: id,
                                      name: '',
                                      enabled: true,
                                      edit: false,
                                      file: false
                                    });
                                  }}
                                >
                                  <AiFillFolderAdd />
                                </IconButton>
                                <IconButton
                                  onClick={async () => {
                                    const id = v.id.slice(1, v.id.length);
                                    await setModalIsOpen({
                                      state: true,
                                      title: 'New File'
                                    });
                                    await setRFolder({
                                      id,
                                      idFolder: id,
                                      name: 'New',
                                      enabled: true,
                                      edit: false,
                                      file: true
                                    });
                                  }}
                                >
                                  <AiFillFileAdd />
                                </IconButton>
                                <div className="divUpload">
                                  <label htmlFor="file-input">
                                    <AiOutlineCloudUpload />
                                  </label>
                                  <input
                                    id="file-input"
                                    type="file"
                                    accept=".arp,"
                                    onChange={async e => await updaloadFile(e)}
                                  />
                                </div>
                                <IconButton
                                  onClick={async () => {
                                    const id = v.id.slice(1, v.id.length);
                                    await setModalIsOpen({
                                      state: true,
                                      title: 'Edit Folder'
                                    });
                                    await setRFolder({
                                      id,
                                      idFolder: v.idFolder,
                                      name: v.name,
                                      enabled: true,
                                      edit: true,
                                      file: false
                                    });
                                  }}
                                >
                                  <AiFillEdit />
                                </IconButton>
                                {v.items.length === 0 && (
                                  <IconButton
                                    onClick={async () => {
                                      const id = v.id.slice(1, v.id.length);
                                      await dispatch(
                                        delFolder(
                                          `${auth.apiPath}/safe/reports/folders/${id}`
                                        )
                                      );

                                      await dispatch(
                                        getFolderPDF(
                                          `${auth.apiPath}/safe/reports/folders`
                                        )
                                      );
                                    }}
                                  >
                                    <AiFillDelete />
                                  </IconButton>
                                )}
                              </span>
                            </>
                          ) : (
                            <>
                              <IoLogoCss3 style={{ marginRight: 5 }} />
                              <p> {v.name} </p>
                              <span style={{ height: 20, marginTop: -5 }}>
                                <IconButton onClick={downloadFile}>
                                  <AiOutlineCloudDownload />
                                </IconButton>
                                <IconButton
                                  onClick={async () => {
                                    const id = v.id.slice(1, v.id.length);
                                    await setModalIsOpen({
                                      state: true,
                                      title: 'Edit File'
                                    });
                                    await setRFolder({
                                      id,
                                      idFolder: v.idFolder,
                                      name: v.name,
                                      enabled: true,
                                      edit: true,
                                      file: true
                                    });
                                  }}
                                >
                                  <AiFillEdit />
                                </IconButton>
                                <IconButton>
                                  <AiFillDelete
                                    onClick={async () => {
                                      const id = v.id.slice(1, v.id.length);
                                      await handleDeleteReport(id);
                                    }}
                                  />
                                </IconButton>
                              </span>
                            </>
                          )}
                        </a>
                      </div>
                    }
                  >
                    {v.items.map(s =>
                      s.type === 'folder' ? (
                        <TreeItem
                          style={{ padding: 5 }}
                          nodeId={s.id}
                          label={
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <a className={classes.reportItemTemplate}>
                                <FolderIcon
                                  color="inherit"
                                  style={{ marginRight: 5 }}
                                />

                                <p> {s.name} </p>
                                <span style={{ height: 20, marginTop: -5 }}>
                                  <IconButton
                                    onClick={async () => {
                                      const id = s.id.slice(1, s.id.length);
                                      await setModalIsOpen({
                                        state: true,
                                        title: 'New Folder'
                                      });
                                      await setRFolder({
                                        id,
                                        idFolder: id,
                                        name: '',
                                        enabled: true,
                                        edit: false,
                                        file: false
                                      });
                                    }}
                                  >
                                    <AiFillFolderAdd />
                                  </IconButton>
                                  <IconButton
                                    onClick={async () => {
                                      const id = v.id.slice(1, v.id.length);
                                      await setModalIsOpen({
                                        state: true,
                                        title: 'New File'
                                      });
                                      await setRFolder({
                                        id,
                                        idFolder: id,
                                        name: '',
                                        enabled: true,
                                        edit: false,
                                        file: true
                                      });
                                    }}
                                  >
                                    <AiFillFileAdd />
                                  </IconButton>

                                  <div className="divUpload">
                                    <label htmlFor="file-input">
                                      <AiOutlineCloudUpload />
                                    </label>
                                    <input
                                      id="file-input"
                                      type="file"
                                      accept=".arp,"
                                      onChange={async e =>
                                        await updaloadFile(e)
                                      }
                                    />
                                  </div>

                                  <IconButton
                                    onClick={async () => {
                                      const id = s.id.slice(1, s.id.length);
                                      await setModalIsOpen({
                                        state: true,
                                        title: 'Edit Folder'
                                      });
                                      await setRFolder({
                                        id,
                                        idFolder: s.idFolder,
                                        name: s.name,
                                        enabled: true,
                                        edit: true,
                                        file: false
                                      });
                                    }}
                                  >
                                    <AiFillEdit />
                                  </IconButton>
                                  {s.items.length === 0 && (
                                    <IconButton
                                      onClick={async () => {
                                        const id = s.id.slice(1, s.id.length);
                                        await dispatch(
                                          delFolder(
                                            `${auth.apiPath}/safe/reports/folders/${id}`
                                          )
                                        );

                                        await dispatch(
                                          getFolderPDF(
                                            `${auth.apiPath}/safe/reports/folders`
                                          )
                                        );
                                      }}
                                    >
                                      <AiFillDelete />
                                    </IconButton>
                                  )}
                                </span>
                              </a>
                            </div>
                          }
                        />
                      ) : (
                        <TreeItem
                          style={{ padding: 5 }}
                          nodeId={s.id}
                          label={
                            <div
                              style={{ display: 'flex', alignItems: 'center' }}
                            >
                              <a
                                className={classes.reportItemTemplate}
                                onClick={async () => {
                                  dispatch(setTitle(s.name));
                                  await getTemplate(s.id, s.currentVersion);
                                }}
                              >
                                <IoLogoCss3 style={{ marginRight: 5 }} />
                                <p> {s.name} </p>
                                <span style={{ height: 20, marginTop: -5 }}>
                                  <IconButton onClick={downloadFile}>
                                    <AiOutlineCloudDownload />
                                  </IconButton>
                                  <IconButton
                                    onClick={async () => {
                                      const id = s.id.slice(1, s.id.length);
                                      await setModalIsOpen({
                                        state: true,
                                        title: 'Edit Report'
                                      });
                                      await setRFolder({
                                        id,
                                        idFolder: s.idFolder,
                                        name: s.name,
                                        enabled: true,
                                        edit: false,
                                        file: false
                                      });
                                    }}
                                  >
                                    <AiFillEdit />
                                  </IconButton>

                                  <IconButton
                                    onClick={async () => {
                                      const id = s.id.slice(1, s.id.length);
                                      await handleDeleteReport(id);
                                    }}
                                  >
                                    <AiFillDelete />
                                  </IconButton>
                                </span>
                              </a>
                            </div>
                          }
                        />
                      )
                    )}
                  </TreeItem>
                ))}
              </TreeItem>
            </TreeView>
          </div>
          <Divider style={{ marginTop: 20 }} />
          <div style={{ marginTop: 10 }}>
            <Tabs>
              <TabList>
                <Tab>
                  <p>Params</p>
                </Tab>
                <Tab>
                  <p>Options</p>
                </Tab>
                <Tab>
                  <p>Properties</p>
                </Tab>
                <Tab>
                  <p>Versions</p>
                </Tab>
              </TabList>

              <TabPanel>
                <div
                  style={{
                    width: '100%',
                    height: '40vh',
                    background: '#FFF',
                    overflow: 'auto',
                    padding: 10
                  }}
                >
                  <div>{renderFormInput()}</div>
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  style={{
                    width: '100%',
                    height: '40vh',
                    background: '#FFF',
                    overflow: 'auto',
                    padding: 10
                  }}
                >
                  {auth.role == 'master' && (
                    <div
                      style={{
                        display: 'flex',
                        marginTop: 10,
                        alignItems: 'center',
                        justifyContent: 'space-between'
                      }}
                    >
                      <p>Read Write</p>

                      <SelectBox
                        width={204}
                        displayExpr="name"
                        valueExpr="value"
                        value={
                          studio.pdf &&
                          studio.pdf.lines &&
                          studio.pdf.lines.readOnlyMode
                        }
                        defaultValue="true"
                        items={[
                          {
                            name: 'True',
                            value: true
                          },
                          {
                            name: 'False',
                            value: false
                          }
                        ]}
                        onValueChanged={async e => {
                          dispatch(setReadOnlyMode(e.value));
                          dispatch(setUnSave(true));
                        }}
                      />
                    </div>
                  )}
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Path</p>
                    <TextField
                      variant="outlined"
                      style={{ width: 204 }}
                      type="text"
                      value={pdfOptions.path}
                      onChange={async e => {
                        dispatch(
                          setPdfOptions({
                            ...pdfOptions,
                            path: e.target.value
                          })
                        );
                        dispatch(setUnSave(true));
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Scale (%)</p>
                    <NumberBox
                      variant="outlined"
                      showSpinButtons
                      width={204}
                      min={10}
                      max={100}
                      value={pdfOptions.scale * 100}
                      onValueChanged={async e => {
                        dispatch(
                          setPdfOptions({
                            ...pdfOptions,
                            scale: e.value / 100
                          })
                        );
                        dispatch(setUnSave(true));
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Background</p>

                    <SelectBox
                      width={204}
                      displayExpr="name"
                      valueExpr="value"
                      value={pdfOptions.printBackground}
                      defaultValue="false"
                      items={[
                        {
                          name: 'Visible',
                          value: true
                        },
                        {
                          name: 'Invisible',
                          value: false
                        }
                      ]}
                      onValueChanged={async e => {
                        dispatch(
                          setPdfOptions({
                            ...pdfOptions,
                            printBackground: e.value
                          })
                        );
                        dispatch(setUnSave(true));
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Position</p>

                    <SelectBox
                      width={204}
                      displayExpr="name"
                      valueExpr="value"
                      value={pdfOptions.landscape}
                      defaultValue="false"
                      items={[
                        {
                          name: 'Landscape',
                          value: true
                        },
                        {
                          name: 'Portrait',
                          value: false
                        }
                      ]}
                      onValueChanged={async e => {
                        dispatch(
                          setPdfOptions({
                            ...pdfOptions,
                            landscape: e.value
                          })
                        );
                        dispatch(setUnSave(true));
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Format</p>
                    <SelectBox
                      width={204}
                      displayExpr="name"
                      valueExpr="name"
                      value={
                        pdfOptions.format === null
                          ? 'Custom'
                          : pdfOptions.format
                      }
                      defaultValue="A4"
                      items={[
                        {
                          name: 'Custom',
                          width: '5',
                          widthUn: 'in',
                          height: '5',
                          heightUn: 'in'
                        },
                        {
                          name: 'Letter',
                          width: '8.5',
                          widthUn: 'in',
                          height: '11',
                          heightUn: 'in'
                        },
                        {
                          name: 'Legal',
                          width: '8.5',
                          widthUn: 'in',
                          height: '14',
                          heightUn: 'in'
                        },
                        {
                          name: 'Tabloid',
                          width: '11',
                          widthUn: 'in',
                          height: '17',
                          heightUn: 'in'
                        },
                        {
                          name: 'Ledger',
                          width: '17',
                          widthUn: 'in',
                          height: '11',
                          heightUn: 'in'
                        },
                        {
                          name: 'A0',
                          width: '33.1',
                          widthUn: 'in',
                          height: '46.8',
                          heightUn: 'in'
                        },
                        {
                          name: 'A1',
                          width: '23.4',
                          widthUn: 'in',
                          height: '33.1',
                          heightUn: 'in'
                        },
                        {
                          name: 'A2',
                          width: '16.54',
                          widthUn: 'in',
                          height: '23.4',
                          heightUn: 'in'
                        },
                        {
                          name: 'A3',
                          width: '11.7',
                          widthUn: 'in',
                          height: '16.54',
                          heightUn: 'in'
                        },
                        {
                          name: 'A4',
                          width: '8.27',
                          widthUn: 'in',
                          height: '11.7',
                          heightUn: 'in'
                        },
                        {
                          name: 'A5',
                          width: '5.83',
                          widthUn: 'in',
                          height: '8.27',
                          heightUn: 'in'
                        },
                        {
                          name: 'A6',
                          width: '4.13',
                          widthUn: 'in',
                          height: '5.83',
                          heightUn: 'in'
                        }
                      ]}
                      onValueChanged={async e => {
                        dispatch(
                          setPdfOptions({
                            ...pdfOptions,
                            format: e.value === 'Custom' ? null : e.value
                          })
                        );
                        dispatch(setUnSave(true));
                      }}
                      onSelectionChanged={e => {
                        if (
                          pdfOptions.width !== e.selectedItem.width ||
                          pdfOptions.widthUn !== e.selectedItem.widthUn ||
                          pdfOptions.height !== e.selectedItem.height ||
                          pdfOptions.heightUn !== e.selectedItem.heightUn
                        ) {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              width: e.selectedItem.width,
                              widthUn: e.selectedItem.widthUn,
                              height: e.selectedItem.height,
                              heightUn: e.selectedItem.heightUn
                            })
                          );
                        }
                      }}
                    />
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Width</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <TextField
                        style={{ width: 80, marginRight: 4 }}
                        variant="outlined"
                        type="number"
                        value={pdfOptions.width}
                        onChange={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              width: e.target.value
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                      <SelectBox
                        width={120}
                        displayExpr="display"
                        valueExpr="name"
                        defaultValue="px"
                        value={pdfOptions.widthUn}
                        items={[
                          {
                            id: 1,
                            name: 'px',
                            display: 'Pixel'
                          },
                          {
                            id: 2,
                            name: 'in',
                            display: 'inch'
                          },
                          {
                            id: 3,
                            name: 'cm',
                            display: 'Centimeter'
                          },
                          {
                            id: 4,
                            name: 'mm',
                            display: 'Millimeter'
                          }
                        ]}
                        onValueChanged={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              widthUn: e.value
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Height</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <TextField
                        style={{ width: 80, marginRight: 4 }}
                        variant="outlined"
                        type="number"
                        value={pdfOptions.height}
                        onChange={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              height: e.target.value
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                      <SelectBox
                        width={120}
                        displayExpr="display"
                        valueExpr="name"
                        defaultValue="px"
                        value={pdfOptions.heightUn}
                        items={[
                          {
                            id: 1,
                            name: 'px',
                            display: 'Pixel'
                          },
                          {
                            id: 2,
                            name: 'in',
                            display: 'inch'
                          },
                          {
                            id: 3,
                            name: 'cm',
                            display: 'Centimeter'
                          },
                          {
                            id: 4,
                            name: 'mm',
                            display: 'Millimeter'
                          }
                        ]}
                        onValueChanged={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              heightUn: e.value
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Margin Top</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <TextField
                        style={{ width: 80, marginRight: 4 }}
                        variant="outlined"
                        type="number"
                        value={pdfOptions.margin.top}
                        onChange={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                top: e.target.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                      <SelectBox
                        width={120}
                        displayExpr="display"
                        valueExpr="name"
                        defaultValue="px"
                        value={pdfOptions.margin.topUn}
                        items={[
                          {
                            id: 1,
                            name: 'px',
                            display: 'Pixel'
                          },
                          {
                            id: 2,
                            name: 'in',
                            display: 'inch'
                          },
                          {
                            id: 3,
                            name: 'cm',
                            display: 'Centimeter'
                          },
                          {
                            id: 4,
                            name: 'mm',
                            display: 'Millimeter'
                          }
                        ]}
                        onValueChanged={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                topUn: e.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Margin Right</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <TextField
                        style={{ width: 80, marginRight: 4 }}
                        variant="outlined"
                        type="number"
                        value={pdfOptions.margin.right}
                        onChange={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                right: e.target.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                      <SelectBox
                        width={120}
                        displayExpr="display"
                        valueExpr="name"
                        defaultValue="px"
                        value={pdfOptions.margin.rightUn}
                        items={[
                          {
                            id: 1,
                            name: 'px',
                            display: 'Pixel'
                          },
                          {
                            id: 2,
                            name: 'in',
                            display: 'inch'
                          },
                          {
                            id: 3,
                            name: 'cm',
                            display: 'Centimeter'
                          },
                          {
                            id: 4,
                            name: 'mm',
                            display: 'Millimeter'
                          }
                        ]}
                        onValueChanged={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                rightUn: e.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Margin Bottom</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <TextField
                        style={{ width: 80, marginRight: 4 }}
                        variant="outlined"
                        type="number"
                        value={pdfOptions.margin.bottom}
                        onChange={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                bottom: e.target.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                      <SelectBox
                        width={120}
                        displayExpr="display"
                        valueExpr="name"
                        defaultValue="px"
                        value={pdfOptions.margin.bottomUn}
                        items={[
                          {
                            id: 1,
                            name: 'px',
                            display: 'Pixel'
                          },
                          {
                            id: 2,
                            name: 'in',
                            display: 'inch'
                          },
                          {
                            id: 3,
                            name: 'cm',
                            display: 'Centimeter'
                          },
                          {
                            id: 4,
                            name: 'mm',
                            display: 'Millimeter'
                          }
                        ]}
                        onValueChanged={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                bottomUn: e.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                    </div>
                  </div>
                  <div
                    style={{
                      display: 'flex',
                      marginTop: 10,
                      alignItems: 'center',
                      justifyContent: 'space-between'
                    }}
                  >
                    <p>Margin Left</p>
                    <div
                      style={{
                        display: 'flex',
                        alignItems: 'center',
                        justifyContent: 'flex-end'
                      }}
                    >
                      <TextField
                        style={{ width: 80, marginRight: 4 }}
                        variant="outlined"
                        type="number"
                        value={pdfOptions.margin.left}
                        onChange={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                left: e.target.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                      <SelectBox
                        width={120}
                        displayExpr="display"
                        valueExpr="name"
                        defaultValue="px"
                        value={pdfOptions.margin.leftUn}
                        items={[
                          {
                            id: 1,
                            name: 'px',
                            display: 'Pixel'
                          },
                          {
                            id: 2,
                            name: 'in',
                            display: 'inch'
                          },
                          {
                            id: 3,
                            name: 'cm',
                            display: 'Centimeter'
                          },
                          {
                            id: 4,
                            name: 'mm',
                            display: 'Millimeter'
                          }
                        ]}
                        onValueChanged={async e => {
                          dispatch(
                            setPdfOptions({
                              ...pdfOptions,
                              margin: {
                                ...pdfOptions.margin,
                                leftUn: e.value
                              }
                            })
                          );
                          dispatch(setUnSave(true));
                        }}
                      />
                    </div>
                  </div>
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  style={{
                    width: '100%',
                    height: '40vh',
                    background: '#FFF',
                    overflow: 'auto',
                    padding: 10
                  }}
                >
                  <div
                    style={{
                      display: 'flex',
                      justifyContent: 'flex-end',
                      marginTop: -10
                    }}
                  >
                    <IconButton onClick={() => setModalIsOpenUP(true)}>
                      <MdAddCircleOutline />
                    </IconButton>
                  </div>
                  <div>{renderFormInputProperties()}</div>
                </div>
              </TabPanel>
              <TabPanel>
                <div
                  style={{
                    width: '100%',
                    height: '40vh',
                    background: '#FFF',
                    overflow: 'auto',
                    padding: 10
                  }}
                >
                  <DataGrid
                    id="gridContainer2"
                    keyExpr="idReport"
                    allowColumnReordering
                    noDataText=""
                    dataSource={studio.pdfVersion && studio.pdfVersion.lines}
                    onRowClick={async e =>
                      dispatch(
                        getPDF(
                          `${auth.apiPath}/safe/reports/${selectedId}/versions/${e.data.version}`
                        )
                      )
                    }
                    onRowRemoving={e => handleDeleteVersion(e)}
                  >
                    <Editing mode="row" useIcons allowDeleting />

                    <Column
                      allowEditing={false}
                      caption="Id"
                      alignment="center"
                      dataField="idReport"
                      visible={false}
                    />
                    <Column caption="" width={30} dataField="current" />
                    <Column
                      allowEditing={false}
                      alignment="center"
                      dataField="version"
                    />
                    <Column dataField="idStep" alignment="center">
                      <Lookup
                        dataSource={EnumIdStep}
                        valueExpr="id"
                        displayExpr="name"
                      />
                    </Column>

                    <Column
                      type="buttons"
                      buttons={[
                        {
                          hint: 'Clone',
                          icon: 'repeat',
                          visible: true,
                          onClick: handleClone
                        },
                        {
                          hint: 'Public',
                          icon: 'globe',
                          visible: true,
                          onClick: handlePublic
                        },
                        'delete'
                      ]}
                    />
                  </DataGrid>
                </div>
              </TabPanel>
            </Tabs>
          </div>

          <Modal
            openModal={modalIsOpen.state}
            setOpenModal={() =>
              setModalIsOpen({ ...modalIsOpen, state: false })
            }
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 20
              }}
            >
              <h3>{modalIsOpen.title}</h3>
            </div>
            <div
              style={{
                width: '100%',
                height: 100,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center'
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'center',
                  marginRight: 20
                }}
              >
                <p style={{ marginRight: 10 }}>Name:</p>
                <TextField
                  id="nameFolders"
                  variant="outlined"
                  width={450}
                  value={rFolder.name}
                  onFocus={handleFocus}
                  onChange={async e =>
                    setRFolder({ ...rFolder, name: e.target.value })
                  }
                />
              </div>
            </div>
            <div
              style={{
                width: '100%',
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Button
                width={120}
                text="Cancel"
                type="danger"
                stylingMode="contained"
                onClick={() => setModalIsOpen({ ...modalIsOpen, state: false })}
              />

              <Button
                width={120}
                text="Save"
                type="success"
                stylingMode="contained"
                onClick={rFolder.file ? newOrEditFile : newOrEditFolder}
              />
            </div>
          </Modal>

          <Modal
            openModal={modalIsOpenUP}
            setOpenModal={() => setModalIsOpenUP(false)}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 20
              }}
            >
              <h3>Add Input</h3>
            </div>
            <div
              style={{
                width: 300,
                height: 120
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 10
                }}
              >
                <p>Name:</p>
                <TextField
                  id="nameInput"
                  variant="outlined"
                  width={204}
                  value={rInput.name}
                  onChange={e => setRInput({ ...rInput, name: e.target.value })}
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 10
                }}
              >
                <p>Value:</p>
                <TextField
                  id="titleInput"
                  variant="outlined"
                  width={204}
                  value={rInput.value}
                  onChange={e =>
                    setRInput({ ...rInput, value: e.target.value })
                  }
                />
              </div>
            </div>

            <div
              style={{
                width: '100%',
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Button
                width={120}
                text="Cancel"
                type="danger"
                stylingMode="contained"
                onClick={() => setModalIsOpenUP(false)}
              />

              <Button
                width={120}
                text="Save"
                type="success"
                stylingMode="contained"
                onClick={addUserPropsValue}
              />
            </div>
          </Modal>

          <Modal
            openModal={modalParams.state}
            setOpenModal={() =>
              setModalParams({ ...modalParams, state: false })
            }
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 20
              }}
            >
              <h3>Edit</h3>
            </div>
            <div
              style={{
                width: 450,
                height: 200
              }}
            >
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 10
                }}
              >
                <p>Title:</p>
                <TextField
                  variant="outlined"
                  style={{ width: 250 }}
                  value={modalParams.params.title}
                  onChange={e =>
                    setModalParams({
                      ...modalParams,
                      params: {
                        ...modalParams.params,
                        title: e.target.value
                      }
                    })
                  }
                />
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 10
                }}
              >
                <p>Type:</p>
                <SelectBox
                  style={{ width: 250 }}
                  displayExpr="name"
                  valueExpr="name"
                  value={modalParams.params.type}
                  items={[
                    {
                      name: 'input-text'
                    },
                    {
                      name: 'input-number'
                    },
                    {
                      name: 'data'
                    },
                    {
                      name: 'select'
                    },
                    {
                      name: 'link'
                    }
                  ]}
                  defaultValue="Input-Text"
                  onValueChanged={e =>
                    setModalParams({
                      ...modalParams,
                      params: {
                        ...modalParams.params,
                        type: e.value
                      }
                    })
                  }
                />
              </div>

              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 10
                }}
              >
                {modalParams.params.type === 'Link' && (
                  <>
                    <p>URL:</p>
                    <TextField
                      variant="outlined"
                      style={{ width: 250 }}
                      value={modalParams.params.url}
                      onChange={e =>
                        setModalParams({
                          ...modalParams,
                          params: {
                            ...modalParams.params,
                            url: e.target.value
                          }
                        })
                      }
                    />
                  </>
                )}
              </div>
              <div
                style={{
                  display: 'flex',
                  alignItems: 'center',
                  justifyContent: 'space-between',
                  margin: 10
                }}
              >
                {modalParams.params.type === 'select' && (
                  <>
                    <p>Options: (;)</p>
                    <TextareaAutosize
                      style={{ width: 250 }}
                      rowsMin={5}
                      value={modalParams.options}
                      onChange={e => {
                        const op = e.target.value.split(';');
                        setModalParams({
                          ...modalParams,
                          params: {
                            ...modalParams.params,
                            options: op
                          }
                        });
                      }}
                    />
                  </>
                )}
              </div>
            </div>

            <div
              style={{
                width: '100%',
                height: 30,
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'space-between'
              }}
            >
              <Button
                width={120}
                text="Cancel"
                type="danger"
                stylingMode="contained"
                onClick={() => setModalParams({ ...modalParams, state: false })}
              />

              <Button
                width={120}
                text="Save"
                type="success"
                stylingMode="contained"
                onClick={alterTypeParams}
              />
            </div>
          </Modal>
        </>
      );
    }
  } catch (err) {
    setInputError(err);
  }
  return <p>Loading...</p>;
};

export default ParamsFilter;
