import { makeStyles } from '@material-ui/core/styles';
// import { store } from '../../store/index';

export const useStyles = makeStyles(theme => ({
  active: {
    backgroundColor: '#fff',
    color: '#000',
    paddingLeft: '14px'
  },

  desactive: {
    backgroundColor: '##009FE3',
    color: '#fff',
    paddingLeft: '14px',
    paddingTop: '10px',

    '& svg': {
      color: '#fff',
      fill: '#fff'
    }
  },
  link: {
    color: '#fff',
    textDecoration: 'none',

    '&:hover': {
      background: 'rgb(0, 143, 204)',
      color: '#000'
    }
  },
  itemText: {
    color: '#fff',
    '&:hover': {
      color: '#808080'
    }
  }
}));
