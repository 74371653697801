/* eslint-disable react/jsx-props-no-spreading */
/* eslint-disable no-unused-vars */
import React from 'react';

import { Route, Redirect } from 'react-router-dom';
import { store } from '../store';

export default function RouteWrapper({
  component: Component,
  isPrivate,
  path,
  ...rest
}) {
  const { signed } = store.getState().auth;

  if (!signed && isPrivate) {
    return <Redirect to="/signin" />;
  }

  if (signed && !isPrivate) {
    return <Redirect to="/users" />;
  }

  return <Route {...rest} component={Component} />;
}
