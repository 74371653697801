import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_FAILURE,
  SIGN_OUT
} from '../types';

export function signInRequest(login, password) {
  return {
    type: SIGN_IN_REQUEST,
    payload: { login, password }
  };
}

export function signInSuccess(token, decodedToken) {
  return {
    type: SIGN_IN_SUCCESS,
    payload: { token, decodedToken }
  };
}
export function signFailure() {
  return { type: SIGN_FAILURE };
}

export function signOut() {
  return {
    type: SIGN_OUT
  };
}

