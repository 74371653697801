/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider } from '@material-ui/core';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import DataGrid, {
  Column,
  Selection,
  Editing,
  Scrolling,
  FormItem
} from 'devextreme-react/data-grid';
import AceEditor from 'react-ace';
import { getReportsSnippets } from 'store/modules/studio/actions';

const Snippet = () => {
  const { studio, auth } = useSelector(state => state);
  const dispatch = useDispatch();
  const [stateSnippets, setStateSnippets] = useState();

  useEffect(() => {
    dispatch(getReportsSnippets(`${auth.apiPath}/safe/reports/snippets`));

    return () => {
      setStateSnippets('');
    };
  }, []);

  return (
    <>
      <Divider style={{ marginTop: 5, marginBottom: 20 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <DataGrid
          keyExpr="id"
          dataSource={
            studio.snippet && studio.snippet.data && studio.snippet.data.lines
          }
          rowAlternationEnabled
          style={{
            height: '350px'
          }}
          showBorders
          remoteOperations
          onRowClick={e => setStateSnippets(e.data)}
          allowColumnResizing
          autoNavigateToFocusedRow
          columnResizingMode="widget"
        >
          <Scrolling mode="infinite" />
          <Selection mode="single" showCheckBoxesMode="onClick" />

          <Column
            width="50"
            dataField="id"
            visible={false}
            alignment="center"
          />
          <Column dataField="name" alignment="left" />
          <Column dataField="code" visible={false} alignment="left" />
          <Column dataField="enabled" visible={false} alignment="left" />
          <Column dataField="id_instance" visible={false} alignment="left" />
          <Column dataField="source_body" visible={false} alignment="left" />
          <Column dataField="source_css" visible={false} alignment="left" />
          <Column dataField="source_head" visible={false} alignment="left" />
          <Column dataField="source_js" visible={false} alignment="left" />
        </DataGrid>
      </div>
      <Divider style={{ marginTop: 10 }} />
      <div>
        <Tabs>
          <TabList>
            <Tab>
              <p>HTML</p>
            </Tab>
            <Tab>
              <p>CSS</p>
            </Tab>
            <Tab>
              <p>JS</p>
            </Tab>
            <Tab>
              <p>HEAD</p>
            </Tab>
          </TabList>
          <TabPanel>
            <div
              style={{
                width: '395px',
                height: '42vh',
                background: '#FFF',
                padding: 10
              }}
            >
              <AceEditor
                readOnly
                height="100%"
                width="100%"
                value={stateSnippets && stateSnippets.source_body}
                placeholder="HTML"
                mode="html"
                theme="github"
                name="htmleditor"
                showPrintMargin
                showGutter
                highlightActiveLine
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2
                }}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div
              style={{
                width: '395px',
                height: '42vh',
                background: '#FFF',
                padding: 10
              }}
            >
              <AceEditor
                readOnly
                height="100%"
                width="100%"
                value={stateSnippets && stateSnippets.source_css}
                placeholder="CSS Editor"
                mode="css"
                theme="github"
                name="csseditor"
                showPrintMargin
                showGutter
                highlightActiveLine
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2
                }}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div
              style={{
                width: '395px',
                height: '42vh',
                background: '#FFF',
                padding: 10
              }}
            >
              <AceEditor
                readOnly
                height="100%"
                width="100%"
                value={stateSnippets && stateSnippets.source_js}
                placeholder="JS Editor"
                mode="javascript"
                theme="github"
                name="jseditor"
                showPrintMargin
                showGutter
                highlightActiveLine
                setOptions={{
                  enableBasicAutocompletion: true,
                  enableLiveAutocompletion: true,
                  enableSnippets: true,
                  showLineNumbers: true,
                  tabSize: 2
                }}
              />
            </div>
          </TabPanel>
          <TabPanel>
            <div
              style={{
                width: '395px',
                height: '42vh',
                background: '#FFF',
                padding: 10
              }}
            >
              <DataGrid
                dataSource={stateSnippets && stateSnippets.source_head}
                rowAlternationEnabled
                style={{
                  height: '100%'
                }}
                showBorders
                allowColumnResizing
                autoNavigateToFocusedRow
                columnResizingMode="widget"
              >
                <Editing mode="form" useIcons allowUpdating />

                <Scrolling mode="infinite" />

                <Column caption="Content" dataField="content" alignment="left">
                  <FormItem
                    colSpan={2}
                    editorType="dxTextArea"
                    editorOptions={{ height: 60 }}
                  />
                </Column>
                <Column type="buttons" width={50} buttons={['edit']} />
              </DataGrid>
            </div>
          </TabPanel>
        </Tabs>
      </div>
    </>
  );
};

export default Snippet;
