/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_TERMS, POST_TERMS } from '../types';

const INITIAL_STATE = {
  terms: null,
  addTerms: null
};

const localization = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_TERMS: {
        draft.terms = action.payload.terms;
        break;
      }
      case POST_TERMS: {
        draft.addTerms = action.payload.addTerms;
        break;
      }

      default:
        break;
    }
  });
};

export default localization;
