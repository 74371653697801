/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useRef } from 'react';
import notify from 'devextreme/ui/notify';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import { CircularProgress } from '@material-ui/core';
import DataGrid, {
  Column,
  Scrolling,
  Sorting,
  HeaderFilter,
  SearchPanel,
  FilterRow
} from 'devextreme-react/data-grid';

import './styles.css';

import { getBuckets } from 'store/modules/bucket/actions';

const useStyles = makeStyles(theme => ({}));
let abertura = false;
const Bucket = () => {
  const classes = useStyles();
  const dataGridRef = useRef();

  const dispatch = useDispatch();
  const { auth, bucket } = useSelector(state => state);

  const [inputError, setInputError] = useState('');

  useEffect(() => {
    if (abertura && inputError) {
      notify(inputError, 'error', 4000);
    }
    abertura = true;
  }, [inputError]);

  useEffect(() => {
    try {
      const loadData = async () => {
        await dispatch(getBuckets(`${auth.apiPath}/logs/buckets`));
      };
      loadData();
    } catch (err) {
      setInputError(err);
    }
  }, []);

  try {
    if (bucket.buckets && bucket.buckets.lines) {
      return (
        <>
          <div style={{ display: 'flex', padding: 10 }}>
            <DataGrid
              ref={dataGridRef}
              dataSource={bucket.buckets.lines}
              rowAlternationEnabled
              id="gridContainer"
              style={{
                height: '90vh',
                width: '100%'
              }}
              showBorders
              remoteOperations
              allowColumnResizing
              autoNavigateToFocusedRow
              columnResizingMode="widget"
            >
              <Scrolling mode="infinite" />
              <Sorting mode="multiple" />
              <HeaderFilter visible />
              <FilterRow visible />
              <SearchPanel visible width={350} placeholder="Search..." />
              <Column dataField="id" visible={false} alignment="left" />
              <Column dataField="businessUnit" caption="Laboratory">
                <HeaderFilter allowSearch />
              </Column>
              <Column
                dataField="date"
                caption="Last backup"
                dataType="datetime"
                format="dd/MM/yyyy, HH:mm"
                width={120}
              >
                <HeaderFilter allowSearch />
              </Column>
              <Column
                dataField="dateLims"
                caption="Last sync"
                dataType="datetime"
                format="dd/MM/yyyy, HH:mm"
                width={120}
              >
                <HeaderFilter allowSearch />
              </Column>
              <Column
                dataField="idBusinessUnit"
                visible={false}
                dataType="number"
              >
                <HeaderFilter allowSearch />
              </Column>
              <Column dataField="ip" caption="IP" alignment="left" width={120}>
                <HeaderFilter allowSearch />
              </Column>
              <Column
                dataField="size"
                caption="Last size"
                alignment="left"
                dataType="string"
                width={100}
              >
                <HeaderFilter allowSearch />
              </Column>
              <Column
                dataField="sizeMax"
                caption="Max size"
                alignment="left"
                dataType="string"
                width={100}
              >
                <HeaderFilter allowSearch />
              </Column>
              <Column
                dataField="outsourced"
                alignment="center"
                dataType="boolean"
                width={100}
              >
                <HeaderFilter allowSearch />
              </Column>
              <Column
                dataField="ams"
                alignment="center"
                caption="AMS"
                dataType="boolean"
                width={100}
              >
                <HeaderFilter allowSearch />
              </Column>
              <Column dataField="credential" alignment="left" dataType="string">
                <HeaderFilter allowSearch />
              </Column>
            </DataGrid>
          </div>
        </>
      );
    }
  } catch (err) {
    setInputError(err);
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 250
      }}
    >
      <CircularProgress style={{ color: '#009fe3' }} size={50} />
    </div>
  );
};

export default Bucket;
