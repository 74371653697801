/* eslint-disable no-unused-vars */
/* eslint-disable import/no-unresolved */
import { takeLatest, call, put, all } from 'redux-saga/effects';
import jwt_decode from 'jwt-decode';
import { toast } from 'react-toastify';
import axios from 'axios';
import getFrontEnvironment from '../../../utils/getEnviroment';
import { signInSuccess, signFailure } from './actions';

import { store } from '../../index';

export function* signIn({ payload }) {
  try {
    const { login, password } = payload;
    const { apiPath } = store.getState().auth;

    const response = yield call(axios.post, `${apiPath}/auth/login`, {
      login,
      password
    });

    const { token } = response.data;

    const decodedToken = jwt_decode(token);

    const permission = userHavePermission(decodedToken.perfil);
    if (!permission) return toast.error('Access denied');

    axios.defaults.headers.Authorization = token;
    yield all([put(signInSuccess(token, decodedToken))]);

    window.location.href = '/';
  } catch (err) {
    toast.error('Invalid email or password');
    yield put(signFailure());
  }
}

function userHavePermission(role) {
  // const env = getFrontEnvironment();
  console.log('Role => ', role);
  // if (['manager', 'customer', 'employee'].includes(role)) return false;
  // if (['production', 'stage', 'local', 'dev'].includes(env))
  //   if (['master', 'admin', 'support'].includes(role)) return true;

  // if (['stage', 'local', 'dev'].includes(env))
  if (['dev', 'tester', 'admin', 'master', 'support'].includes(role))
    return true;
  return false;
}

export function setHeaders({ payload }) {
  if (!payload) return;

  const { token } = payload.auth;

  if (token) {
    axios.defaults.headers.Authorization = token;
  }
}
export function signOut() {
  sessionStorage.clear();
  window.location.href = '/';
}

export default all([
  takeLatest('persist/REHYDRATE', setHeaders),
  takeLatest('@auth/SIGN_IN_REQUEST', signIn),
  takeLatest('@auth/SIGN_OUT', signOut)
]);
