/* eslint-disable no-nested-ternary */
/* eslint-disable no-console */
/* eslint-disable react/button-has-type */
import React, { useEffect, useState, useRef } from 'react';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { useDispatch, useSelector } from 'react-redux';
import { makeStyles } from '@material-ui/core/styles';
import AceEditor from 'react-ace';
import notify from 'devextreme/ui/notify';
import AddIcon from '@material-ui/icons/Add';
import SaveIcon from '@material-ui/icons/Save';
import { Modal } from 'componentes';
import {
  Button,
  IconButton,
  CircularProgress,
  TextareaAutosize
} from '@material-ui/core';
import DataGrid, {
  Column,
  Selection,
  Editing,
  Scrolling,
  FormItem,
  RowDragging
} from 'devextreme-react/data-grid';

import PlayCircleOutlineIcon from '@material-ui/icons/PlayCircleOutline';
import 'ace-builds/src-noconflict/mode-javascript';
import 'ace-builds/src-noconflict/mode-sql';
import 'ace-builds/src-noconflict/mode-html';
import 'ace-builds/src-noconflict/mode-css';
import 'ace-builds/src-noconflict/mode-json';
import 'ace-builds/src-noconflict/theme-github';
import 'ace-builds/src-noconflict/ext-language_tools';
import CodeIcon from '@material-ui/icons/Code';
import 'devextreme-react/text-area';
import {
  setSql,
  postJSON,
  setJS,
  setCss,
  setBody,
  putPDF,
  getFolderPDF,
  getPDF,
  putReports,
  setUnSave,
  setReadOnlyAce,
  setHead,
  setLoading,
  setOpenMenuStudio,
  setRedParams
} from 'store/modules/studio/actions';

import 'react-tabs/style/react-tabs.css';
import './styles.css';

import beautify from 'js-beautify';
import sqlFormatter from 'sql-formatter';
import ToolTip from 'componentes/Toolpit';

const useStyles = makeStyles(theme => ({
  buttonExpand: {
    width: '80px',
    height: '30px'
  },
  root: {
    display: 'flex',
    padding: 10
  },
  folders: {
    height: 216,
    flexGrow: 1,
    maxWidth: 300
  },
  buttonCancel: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#F53623',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#A63623',
      opacity: '10'
    }
  },
  buttonSave: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#FFCD00',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#FFCD50',
      opacity: '10'
    }
  },
  form: {
    backgroundColor: '#fff',
    width: '400px',

    borderRadius: '6px',

    '& h3': {
      textAlign: 'center',
      marginBottom: '10px'
    },

    '& button': {
      cursor: 'pointer',
      height: '36px',
      margin: theme.spacing(1, 0, 1),
      backgroundColor: '#FFCD00',
      color: '#F0F0F0',
      borderRadius: '5px',
      border: `1px solid #F0F0F0`,
      outline: 0,
      '&:hover': {
        backgroundColor: '#FFCD00',
        opacity: '10'
      }
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',

      '& input': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #F0F0F0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginBottom: '15px',
        backgroundColor: '#fff',

        '&:focus': {
          border: `1px solid #FFCD00`,
          color: '#000'
        }
      }
    }
  },
  list: {
    '&:hover': {},
    '& svg': {
      fontSize: '14px'
    }
  },
  reportItem: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  },

  reportItemTemplate: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& a': {
      display: 'inline-block'
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  }
}));

let abertura = false;

const optionsFormat = {
  indent_size: 4,
  indent_char: ' ',
  indent_with_tabs: false,
  eol: '\n',
  end_with_newline: false,
  indent_level: 0,
  preserve_newlines: true,
  max_preserve_newlines: 10,
  space_in_paren: false,
  space_in_empty_paren: false,
  jslint_happy: false,
  space_after_anon_function: false,
  brace_style: 'collapse',
  unindent_chained_methods: false,
  break_chained_methods: false,
  keep_array_indentation: false,
  unescape_strings: false,
  wrap_line_length: 0,
  e4x: false,
  comma_first: false,
  operator_position: 'before-newline'
};

const Report = () => {
  const classes = useStyles();
  const dataGridRef = useRef();

  const dispatch = useDispatch();
  const { auth, studio } = useSelector(state => state);
  const [inputError, setInputError] = useState('');
  const [inputType, setInputType] = useState('');
  const [viewHead, setViewHead] = useState({
    link: '',
    modal: false
  });

  useEffect(() => {
    console.log('1');
    if (studio.redHead) {
      const ret = studio.redHead.map(v => {
        return {
          content: v.content
        };
      });
      setHead(ret);
    }
  }, [studio.redHead]);

  useEffect(() => {
    console.log('2');
    try {
      const loadData = async () => {
        dispatch(setReadOnlyAce(false));
        dispatch(getFolderPDF(`${auth.apiPath}/safe/reports/folders`));
      };
      loadData();
    } catch (err) {
      setInputError(err);
    }
  }, []);

  useEffect(() => {
    console.log('3');
    if (abertura && inputError) {
      notify(inputError, 'error', 4000);
    }
    abertura = true;
  }, [inputError]);

  useEffect(() => {
    console.log('5');
    if (studio.redJson && studio.redJson.status !== 200) {
      setInputError(studio.redJson.message);
    }
  }, [studio.redJson]);

  const onSave = async () => {
    try {
      const obj = {
        idFolder: studio.pdf.lines.idFolder,
        name: studio.pdf.lines.name,
        description: studio.pdf.lines.description,
        enabled: studio.pdf.lines.enabled,
        userProps: studio.pdf.lines.userProps,
        readOnlyMode: studio.readOnlyMode,
        pdfOptions: {
          format: studio.pdfOptions.format,
          height: `${studio.pdfOptions.height}${studio.pdfOptions.heightUn}`,
          landscape: studio.pdfOptions.landscape,
          margin: {
            bottom: `${studio.pdfOptions.margin.bottom}${studio.pdfOptions.margin.bottomUn}`,
            left: `${studio.pdfOptions.margin.left}${studio.pdfOptions.margin.leftUn}`,
            right: `${studio.pdfOptions.margin.right}${studio.pdfOptions.margin.rightUn}`,
            top: `${studio.pdfOptions.margin.top}${studio.pdfOptions.margin.topUn}`
          },
          path: studio.pdfOptions.path,
          printBackground: studio.pdfOptions.printBackground,
          scale: studio.pdfOptions.scale,
          width: `${studio.pdfOptions.width}${studio.pdfOptions.widthUn}`
        },
        sourceSql: studio.redSql,
        sourceParams: studio.redParams,
        sourceHead: studio.redHead,
        sourceBody: studio.redBody,
        sourceCss: studio.redCss,
        sourceJs: studio.redJS
      };

      await dispatch(
        putReports(
          `${auth.apiPath}/safe/reports/${studio.pdf.lines.idReport}/versions/${studio.currentVersion}`,
          obj
        )
      );

      dispatch(setUnSave(false));
    } catch (err) {
      setInputError(err);
    }
  };

  async function executeHTML() {
    try {
      if (studio.pdf && studio.pdf.lines.idReport) {
        dispatch(setLoading(true));
        setInputType('html');
        await onSave();
      }
    } catch (err) {
      dispatch(setLoading(false));
      setInputError(err);
    }
  }

  async function executeJson() {
    try {
      if (studio.pdf && studio.pdf.lines && studio.pdf.lines.idReport) {
        dispatch(setLoading(true));
        setInputType('json');
        await onSave();
      }
    } catch (err) {
      dispatch(setLoading(false));
      setInputError(err);
    }
  }

  useEffect(() => {
    console.log('4');
    const loadEffect = async () => {
      try {
        if (
          studio.report &&
          studio.report.lines &&
          studio.pdf &&
          studio.pdf.lines
        ) {
          switch (inputType) {
            case 'json':
              await dispatch(
                postJSON(
                  `${auth.apiPath}/safe/reports/${studio.pdf.lines.idReport}/versions/${studio.currentVersion}/json`,
                  studio.params
                )
              );
              if (studio.report.lines[0].sourceParams.length > 0) {
                dispatch(setRedParams(studio.report.lines[0].sourceParams));
              }
              break;
            case 'html':
              await dispatch(
                putPDF(
                  `${auth.apiPath}/safe/reports/${studio.pdf.lines.idReport}/versions/${studio.currentVersion}/pdf`,
                  studio.params
                )
              );
              break;
            default:
              break;
          }
          setInputType('');
          dispatch(setLoading(false));
        } else {
          setInputError(studio.report && studio.report.message);
          dispatch(setLoading(false));
        }
      } catch (err) {
        dispatch(setLoading(false));
        setInputError(err);
      }
    };
    loadEffect();
  }, [studio.report && studio.report.lines]);

  const onRowInserting = e => {
    const newHead = studio.redHead.map(v => v);
    newHead.push(e.data);
    dispatch(setHead(newHead));
  };

  const onRowUpdating = e => {
    const newHead = studio.redHead.map(v => {
      if (v.content === e.oldData.content) return e.newData;
      return v;
    });
    dispatch(setHead(newHead));
  };

  const onRowRemoving = e => {
    const newHead = studio.redHead.filter(v => v.content !== e.data.content);
    dispatch(setHead(newHead));
  };

  const onRowView = e => {
    const newContent = e.row.data.content;
    const viewLinkSrc = newContent.indexOf('src');
    const viewLinkHref = newContent.indexOf('href');
    if (viewLinkSrc >= 0) {
      const newLink = newContent.substring(
        newContent.indexOf('src="') + 5,
        newContent.indexOf('">')
      );

      setViewHead({
        link: newLink,
        modal: true
      });
    } else if (viewLinkHref >= 0) {
      const newLink = newContent.substring(
        newContent.indexOf('href="') + 6,
        newContent.indexOf('" />')
      );

      setViewHead({
        link: newLink,
        modal: true
      });
    } else {
      notify('no link available', 'error', 2000);
    }
  };

  const onReorder = async e => {
    const visibleRows = e.component.getVisibleRows();
    const toIndex = studio.redHead.indexOf(visibleRows[e.toIndex].data);
    const fromIndex = studio.redHead.indexOf(e.itemData);
    let newArray = [];
    newArray = visibleRows.map(v => v.data);
    newArray.splice(fromIndex, 1);
    newArray.splice(toIndex, 0, e.itemData);

    dispatch(setHead(newArray));

    setTimeout(() => {
      e.component.refresh();
    }, 500);
  };

  const formaterCode = e => {
    switch (e) {
      case 'html':
        dispatch(setBody(beautify.html(studio.redBody, optionsFormat)));
        break;
      case 'css':
        dispatch(setCss(beautify.css(studio.redCss, optionsFormat)));
        break;
      case 'js':
        dispatch(setJS(beautify.js(studio.redJS, optionsFormat)));
        break;
      case 'sql':
        dispatch(setSql(sqlFormatter.format(studio.redSql)));
        break;
      default:
        break;
    }
    dispatch(setUnSave(true));
  };

  try {
    if (studio.folderPdf) {
      return (
        <>
          <div style={{ display: 'flex', padding: 10 }}>
            <div
              style={{
                display: 'block',
                marginLeft: 20
              }}
            >
              <div
                style={{
                  display: 'flex',
                  justifyContent: 'flex-end',
                  marginTop: 10,
                  marginBottom: -35
                }}
              >
                <IconButton
                  style={{ height: 20, width: 20, color: '#009FE3' }}
                  onClick={() => onSave()}
                >
                  <SaveIcon />
                </IconButton>
              </div>
              <Tabs>
                <TabList style={{ float: 'left' }}>
                  <Tab>
                    <div style={{ width: 75, display: 'flex' }}>
                      <p>SQL</p>
                      <ToolTip title="Format SQL" placement="right">
                        <IconButton
                          style={{
                            marginLeft: 20,
                            marginTop: -2,
                            width: 20,
                            height: 20,
                            color: '#FFF'
                          }}
                          onClick={() => formaterCode('sql')}
                        >
                          <CodeIcon />
                        </IconButton>
                      </ToolTip>
                    </div>
                  </Tab>
                  <Tab>
                    <p>HEAD</p>
                  </Tab>
                  <Tab>
                    <div style={{ width: 75, display: 'flex' }}>
                      <p>BODY</p>
                      <ToolTip title="Format HTML" placement="right">
                        <IconButton
                          style={{
                            marginLeft: 20,
                            marginTop: -2,
                            width: 20,
                            height: 20,
                            color: '#FFF'
                          }}
                          onClick={() => formaterCode('html')}
                        >
                          <CodeIcon />
                        </IconButton>
                      </ToolTip>
                    </div>
                  </Tab>
                  <Tab>
                    <div style={{ width: 65, display: 'flex' }}>
                      <p>CSS</p>
                      <ToolTip title="Format CSS" placement="right">
                        <IconButton
                          style={{
                            marginLeft: 20,
                            marginTop: -2,
                            width: 20,
                            height: 20,
                            color: '#FFF'
                          }}
                          onClick={() => formaterCode('css')}
                        >
                          <CodeIcon />
                        </IconButton>
                      </ToolTip>
                    </div>
                  </Tab>
                  <Tab>
                    <div style={{ width: 55, display: 'flex' }}>
                      <p>JS</p>
                      <ToolTip title="Format JS" placement="right">
                        <IconButton
                          style={{
                            marginLeft: 20,
                            marginTop: -2,
                            width: 20,
                            height: 20,
                            color: '#FFF'
                          }}
                          onClick={() => formaterCode('js')}
                        >
                          <CodeIcon />
                        </IconButton>
                      </ToolTip>
                    </div>
                  </Tab>
                </TabList>
                <TabPanel>
                  {studio.loadingTab ? (
                    <div
                      style={{
                        width: studio.openMenuStudio ? '30vw' : '53vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 250
                      }}
                    >
                      <CircularProgress
                        style={{ color: '#009fe3' }}
                        size={50}
                      />
                    </div>
                  ) : (
                    <AceEditor
                      readOnly={studio.readOnlyAce}
                      height="85vh"
                      width={studio.openMenuStudio ? '30vw' : '53vw'}
                      value={studio.redSql}
                      onChange={newValue => {
                        dispatch(setSql(newValue));
                        dispatch(setUnSave(true));
                      }}
                      placeholder="Sql Editor"
                      mode="javascript"
                      theme="github"
                      name="sqleditor"
                      showPrintMargin
                      showGutter
                      highlightActiveLine
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {studio.loadingTab ? (
                    <div
                      style={{
                        width: studio.openMenuStudio ? '30vw' : '53vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 250
                      }}
                    >
                      <CircularProgress
                        style={{ color: '#009fe3' }}
                        size={50}
                      />
                    </div>
                  ) : (
                    <div style={{ paddingTop: 15 }}>
                      <div
                        style={{
                          height: 20,
                          display: 'flex',
                          justifyContent: 'flex-end',
                          paddingTop: 32,
                          zIndex: 1
                        }}
                      >
                        <IconButton
                          onClick={() => dataGridRef.current.instance.addRow()}
                          style={{
                            color: '#fff',
                            backgroundColor: '#337ab7',
                            height: 12,
                            width: 12,
                            marginTop: 4,
                            marginRight: 26,
                            zIndex: 1
                          }}
                        >
                          <AddIcon />
                        </IconButton>
                      </div>
                      <DataGrid
                        ref={dataGridRef}
                        dataSource={studio.redHead}
                        rowAlternationEnabled
                        style={{
                          height: '85vh',
                          width: studio.openMenuStudio ? '30vw' : '53vw',
                          zIndex: -1
                        }}
                        showBorders
                        remoteOperations
                        allowColumnResizing
                        autoNavigateToFocusedRow
                        columnResizingMode="widget"
                        onRowInserting={onRowInserting}
                        onRowUpdating={onRowUpdating}
                        onRowRemoving={onRowRemoving}
                      >
                        <RowDragging
                          allowReordering
                          onReorder={onReorder}
                          showDragIcons
                        />
                        <Editing
                          mode="form"
                          useIcons
                          allowUpdating
                          allowDeleting
                        />
                        <Scrolling mode="infinite" />
                        <Column
                          caption="Content"
                          dataField="content"
                          alignment="left"
                        >
                          <FormItem
                            colSpan={2}
                            editorType="dxTextArea"
                            editorOptions={{ height: 80 }}
                          />
                        </Column>
                        <Column
                          type="buttons"
                          width={90}
                          buttons={[
                            {
                              hint: 'View',
                              icon: 'variable',
                              visible: true,
                              onClick: e => onRowView(e)
                            },
                            'edit',
                            'delete'
                          ]}
                        />
                      </DataGrid>
                    </div>
                  )}
                </TabPanel>
                <TabPanel>
                  {studio.loadingTab ? (
                    <div
                      style={{
                        width: studio.openMenuStudio ? '30vw' : '53vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 250
                      }}
                    >
                      <CircularProgress
                        style={{ color: '#009fe3' }}
                        size={50}
                      />
                    </div>
                  ) : (
                    <AceEditor
                      readOnly={studio.readOnlyAce}
                      height="85vh"
                      width={studio.openMenuStudio ? '30vw' : '53vw'}
                      value={studio.redBody}
                      onChange={newValue => {
                        dispatch(setUnSave(true));
                        dispatch(setBody(newValue));
                      }}
                      placeholder="HTML"
                      mode="html"
                      theme="github"
                      name="htmleditor"
                      showPrintMargin
                      showGutter
                      highlightActiveLine
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {studio.loadingTab ? (
                    <div
                      style={{
                        width: studio.openMenuStudio ? '30vw' : '53vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 250
                      }}
                    >
                      <CircularProgress
                        style={{ color: '#009fe3' }}
                        size={50}
                      />
                    </div>
                  ) : (
                    <AceEditor
                      readOnly={studio.readOnlyAce}
                      height="85vh"
                      width={studio.openMenuStudio ? '30vw' : '53vw'}
                      value={studio.redCss}
                      onChange={newValue => {
                        dispatch(setCss(newValue));
                        dispatch(setUnSave(true));
                      }}
                      placeholder="CSS Editor"
                      mode="css"
                      theme="github"
                      name="csseditor"
                      showPrintMargin
                      showGutter
                      highlightActiveLine
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  {studio.loadingTab ? (
                    <div
                      style={{
                        width: studio.openMenuStudio ? '30vw' : '53vw',
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 250
                      }}
                    >
                      <CircularProgress
                        style={{ color: '#009fe3' }}
                        size={50}
                      />
                    </div>
                  ) : (
                    <AceEditor
                      readOnly={studio.readOnlyAce}
                      height="85vh"
                      width={studio.openMenuStudio ? '30vw' : '53vw'}
                      value={studio.redJS}
                      onChange={newValue => {
                        dispatch(setJS(newValue));
                        dispatch(setUnSave(true));
                      }}
                      placeholder="JS Editor"
                      mode="javascript"
                      theme="github"
                      name="jseditor"
                      showPrintMargin
                      showGutter
                      highlightActiveLine
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: true,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                    />
                  )}
                </TabPanel>
              </Tabs>
            </div>
            <div
              style={{
                display: 'block',
                marginLeft: 20
              }}
            >
              <Tabs>
                <TabList>
                  <Tab>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 100
                      }}
                    >
                      <p>JSON</p>
                      <IconButton
                        style={{ height: 20, width: 20, color: '#fff' }}
                        onClick={() => executeJson()}
                      >
                        <PlayCircleOutlineIcon />
                      </IconButton>
                    </div>
                  </Tab>
                  <Tab>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 100
                      }}
                    >
                      <p>Messages</p>
                      <IconButton
                        style={{ height: 20, width: 20, color: '#fff' }}
                      />
                    </div>
                  </Tab>
                  <Tab>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 100
                      }}
                    >
                      <p>PDF</p>
                      <IconButton
                        style={{ height: 20, width: 20, color: '#fff' }}
                        onClick={() => executeHTML()}
                      >
                        <PlayCircleOutlineIcon />
                      </IconButton>
                    </div>
                  </Tab>
                  <Tab>
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'space-between',
                        alignItems: 'center',
                        width: 100
                      }}
                    >
                      <p>Console</p>
                      <IconButton
                        style={{ height: 20, width: 20, color: '#fff' }}
                      />
                    </div>
                  </Tab>
                </TabList>
                <TabPanel>
                  {studio.loading ? (
                    <div
                      style={{
                        display: 'flex',
                        justifyContent: 'center',
                        alignItems: 'center',
                        paddingTop: 250
                      }}
                    >
                      <CircularProgress
                        style={{ color: '#009fe3' }}
                        size={50}
                      />
                    </div>
                  ) : (
                    <AceEditor
                      height="85vh"
                      width={550}
                      value={
                        studio.redJson &&
                        studio.redJson.lines &&
                        JSON.stringify(studio.redJson.lines, null, 2)
                      }
                      placeholder="JSON"
                      mode="json"
                      theme="github"
                      name="sqleditor"
                      showPrintMargin
                      showGutter
                      highlightActiveLine
                      setOptions={{
                        enableBasicAutocompletion: true,
                        enableLiveAutocompletion: true,
                        enableSnippets: false,
                        showLineNumbers: true,
                        tabSize: 2
                      }}
                    />
                  )}
                </TabPanel>
                <TabPanel>
                  <AceEditor
                    height="85vh"
                    width={550}
                    value={
                      studio.redJson &&
                      studio.redJson.lines &&
                      `Tempo de execução: ${
                        studio.redJson.elapsedTime
                      }\n\r${JSON.stringify(
                        studio.redJson.lines.notices,
                        null,
                        2
                      )}`
                    }
                    placeholder="JSON"
                    mode="json"
                    theme="github"
                    name="sqleditor"
                    showPrintMargin
                    showGutter
                    highlightActiveLine
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                  />
                </TabPanel>
                <TabPanel>
                  <div style={{ width: 550, height: '85vh' }}>
                    {studio.loading ? (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: 250
                        }}
                      >
                        <CircularProgress
                          style={{ color: '#009fe3' }}
                          size={50}
                        />
                      </div>
                    ) : studio.pdfTemplate &&
                      studio.pdfTemplate.lines &&
                      studio.pdfTemplate.lines.body ? (
                      <iframe
                        title="Preview"
                        src={`data:application/pdf;base64,${studio.pdfTemplate.lines.body}`}
                        width="100%"
                        height="100%"
                      />
                    ) : (
                      <div
                        style={{
                          display: 'flex',
                          justifyContent: 'center',
                          alignItems: 'center',
                          paddingTop: 250
                        }}
                      >
                        <h2>Retry!</h2>
                      </div>
                    )}
                  </div>
                </TabPanel>
                <TabPanel>
                  <AceEditor
                    height="85vh"
                    width={550}
                    value={
                      studio.pdfTemplate &&
                      studio.pdfTemplate.lines &&
                      JSON.stringify(studio.pdfTemplate.lines.logs, null, 2)
                    }
                    placeholder="JSON"
                    mode="json"
                    theme="github"
                    name="sqleditor"
                    showPrintMargin
                    showGutter
                    highlightActiveLine
                    setOptions={{
                      enableBasicAutocompletion: true,
                      enableLiveAutocompletion: true,
                      enableSnippets: false,
                      showLineNumbers: true,
                      tabSize: 2
                    }}
                  />
                </TabPanel>
              </Tabs>
            </div>
          </div>

          <Modal
            openModal={viewHead.modal}
            setOpenModal={() => setViewHead({ ...viewHead, modal: false })}
          >
            <div
              style={{
                display: 'flex',
                alignItems: 'center',
                justifyContent: 'center',
                height: 20
              }}
            >
              <h3>Content</h3>
            </div>
            <div
              style={{
                width: '80vw',
                height: '80vh'
              }}
            >
              <iframe
                src={viewHead.link}
                style={{ width: '100%', height: '100%' }}
              />
            </div>
          </Modal>
        </>
      );
    }
  } catch (err) {
    setInputError(err);
  }

  return (
    <div
      style={{
        display: 'flex',
        justifyContent: 'center',
        alignItems: 'center',
        paddingTop: 250
      }}
    >
      <CircularProgress style={{ color: '#009fe3' }} size={50} />
    </div>
  );
};

export default Report;
