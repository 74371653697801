import React, { useState } from 'react';
import { LoadIndicator } from 'devextreme-react/load-indicator';

import './styles.css';

const Loading = ({ time }) => {
  const [loadIndicatorVisible, setLoadIndicatorVisible] = useState(true);
  if (time) setTimeout(() => setLoadIndicatorVisible(false), time);

  return (
    <LoadIndicator
      id="circle"
      visible={loadIndicatorVisible}
      height={60}
      width={60}
    />
  );
};

export default Loading;
