import axios from 'axios';
import getElapsedTime from 'utils/getElapsedTime';
import notify from 'devextreme/ui/notify';
import {
  SET_SQL,
  SET_CSS,
  SET_JS,
  SET_HEAD,
  SET_BODY,
  SET_PARAMS,
  PUT_PDF,
  GET_FOLDER_PDF,
  GET_PDF,
  POST_JSON,
  SET_OPEN_MENU_STUDIO,
  PUT_REPORTS,
  PUT_REPORTS_FOLDERS,
  POST_REPORTS_FOLDERS,
  DEL_FOLDER,
  POST_REPORTS,
  SET_PDF_OPTIONS,
  SET_TITLE,
  SET_UNSAVE,
  SET_USER_PROPS,
  SET_READ_ONLY_ACE,
  PUT_REPORTS_VERSION,
  POST_REPORTS_VERSION,
  GET_PDF_VERSION,
  SET_CURRENT_VERSION,
  GET_SNIPPETS,
  GET_DATABASE,
  IS_LOADING,
  SET_READ_ONLY_MODE,
  IS_LOADING_TAB,
  SET_RED_PARAMS
} from '../types';

export function setUnSave(unSave) {
  return {
    type: SET_UNSAVE,
    payload: { unSave }
  };
}

export function setTitle(title) {
  return {
    type: SET_TITLE,
    payload: { title }
  };
}

export function setReadOnlyAce(readOnlyAce) {
  return {
    type: SET_READ_ONLY_ACE,
    payload: { readOnlyAce }
  };
}

export function setReadOnlyMode(readOnlyMode) {
  return {
    type: SET_READ_ONLY_MODE,
    payload: { readOnlyMode }
  };
}

export function setSql(redSql) {
  return {
    type: SET_SQL,
    payload: { redSql }
  };
}

export function setCss(redCss) {
  return {
    type: SET_CSS,
    payload: { redCss }
  };
}

export function setJS(redJS) {
  return {
    type: SET_JS,
    payload: { redJS }
  };
}

export function setHead(redHead) {
  return {
    type: SET_HEAD,
    payload: { redHead }
  };
}

export function setRedParams(redParams) {
  return {
    type: SET_RED_PARAMS,
    payload: { redParams }
  };
}

export function setCurrentVersion(currentVersion) {
  return {
    type: SET_CURRENT_VERSION,
    payload: { currentVersion }
  };
}

export function setParams(params) {
  return {
    type: SET_PARAMS,
    payload: { params }
  };
}

export function setUserPropsValue(userPropsValue) {
  return {
    type: SET_USER_PROPS,
    payload: { userPropsValue }
  };
}

export function setOpenMenuStudio(open) {
  return {
    type: SET_OPEN_MENU_STUDIO,
    payload: { open }
  };
}

export function setBody(redBody) {
  return {
    type: SET_BODY,
    payload: { redBody }
  };
}

export function setPdfOptions(pdfOptions) {
  return {
    type: SET_PDF_OPTIONS,
    payload: { pdfOptions }
  };
}

export function setLoading(loading) {
  return {
    type: IS_LOADING,
    payload: { loading }
  };
}

export function setLoadingTab(loadingTab) {
  return {
    type: IS_LOADING_TAB,
    payload: { loadingTab }
  };
}

const putPDFSuccess = pdfTemplate => {
  return {
    type: PUT_PDF,
    payload: { pdfTemplate }
  };
};

export const putPDF = (url, obj) => {
  return async dispatch => {
    const resp = await axios.put(url, obj);

    dispatch(putPDFSuccess(resp.data));
  };
};

const putReportsSuccess = report => {
  return {
    type: PUT_REPORTS,
    payload: { report }
  };
};

export const putReports = (url, obj) => {
  return async dispatch => {
    const resp = await axios.put(url, obj);
    if (resp.data.success) {
      notify('Success Save', 'success', 2000);
    }
    dispatch(putReportsSuccess(resp.data));
  };
};

const putReportsVersionSuccess = reportVersion => {
  return {
    type: PUT_REPORTS_VERSION,
    payload: { reportVersion }
  };
};

export const putReportsVersion = (url, obj) => {
  return dispatch => {
    axios
      .put(url, obj)
      .then(response => {
        dispatch(putReportsVersionSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const postReportsSuccess = report => {
  return {
    type: POST_REPORTS,
    payload: { report }
  };
};

export const postReports = (url, obj) => {
  return async dispatch => {
    const resp = await axios.post(url, obj);
    dispatch(postReportsSuccess(resp.data));
  };
};

const postReportsVersionSuccess = reportVersion => {
  return {
    type: POST_REPORTS_VERSION,
    payload: { reportVersion }
  };
};

export const postReportsVersion = (url, obj) => {
  return dispatch => {
    axios
      .post(url, obj)
      .then(response => {
        dispatch(postReportsVersionSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const putReportsFoldersSuccess = folders => {
  return {
    type: PUT_REPORTS_FOLDERS,
    payload: { folders }
  };
};

export const putReportsFolders = (url, obj) => {
  return async dispatch => {
    const resp = await axios.put(url, obj);
    dispatch(putReportsFoldersSuccess(resp.data));
  };
};

const postReportsFoldersSuccess = folders => {
  return {
    type: POST_REPORTS_FOLDERS,
    payload: { folders }
  };
};

export const postReportsFolders = (url, obj) => {
  return async dispatch => {
    const resp = await axios.post(url, obj);
    dispatch(postReportsFoldersSuccess(resp.data));
  };
};

const postJSONSuccess = (json, dispatch) => {
  dispatch(setLoading(false));
  return {
    type: POST_JSON,
    payload: { json }
  };
};

export const postJSON = (url, obj) => {
  return async dispatch => {
    const resp = await axios.post(url, obj);

    dispatch(postJSONSuccess(resp.data, dispatch));
  };
};

const getFolderPDFSuccess = folderPdf => {
  return {
    type: GET_FOLDER_PDF,
    payload: { folderPdf }
  };
};

export const getFolderPDF = url => {
  return async dispatch => {
    const resp = await axios.get(url);
    dispatch(getFolderPDFSuccess(resp.data));
  };
};

const getDatabaseSuccess = database => {
  return {
    type: GET_DATABASE,
    payload: { database }
  };
};

export const getDatabase = url => {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getDatabaseSuccess(response.data));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const getReportsSnippetsSuccess = snippet => {
  return {
    type: GET_SNIPPETS,
    payload: { snippet }
  };
};

export const getReportsSnippets = url => {
  return dispatch => {
    axios
      .get(url)
      .then(response => {
        dispatch(getReportsSnippetsSuccess(response));
      })
      .catch(error => {
        console.log(error);
      });
  };
};

const delFolderSuccess = folderPdf => {
  return {
    type: DEL_FOLDER,
    payload: { folderPdf }
  };
};

export const delFolder = url => {
  return async dispatch => {
    const resp = await axios.delete(url);
    dispatch(delFolderSuccess(resp.data));
  };
};

const getPDFSuccess = pdf => {
  return {
    type: GET_PDF,
    payload: { pdf }
  };
};

export const getPDF = url => {
  return async dispatch => {
    const resp = await axios.get(url);
    dispatch(getPDFSuccess(resp.data));
  };
};

const getPDFVersionSuccess = pdfVersion => {
  return {
    type: GET_PDF_VERSION,
    payload: { pdfVersion }
  };
};

export const getPDFVersion = url => {
  return async dispatch => {
    const resp = await axios.get(url);
    dispatch(getPDFVersionSuccess(resp.data));
  };
};
