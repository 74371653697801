import { combineReducers } from 'redux';

import auth from './auth/reducers';
import normalizer from './normalizer/reducers';
import studio from './studio/reducers';
import bucket from './bucket/reducers';
import localization from './localization/reducers';

export default combineReducers({
  auth,
  normalizer,
  studio,
  bucket,
  localization
});
