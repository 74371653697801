/* eslint-disable react/jsx-props-no-spreading */
import React from 'react';
import { Tooltip } from '@material-ui/core';
import { withStyles , makeStyles } from '@material-ui/core/styles';



const ToolTip = props => {


  const ColorTooltip = withStyles({
    tooltip: {
      color: "#fff",
      backgroundColor: "#FFCD00",
      fontSize: "12px"
    }
  })(Tooltip);

  const useStylesBootstrap = makeStyles(theme => ({
    arrow: {
      color: "#FFCD00"
    },
    tooltip: {
      fontWeight: 'bold',
      color: "#000",
      backgroundColor: "#FFCD00"
    }
  }));
  const classes = useStylesBootstrap();
  return <ColorTooltip arrow classes={classes} {...props} />;
};

export default ToolTip;
