import React from 'react';
import './config/ReactotronConfig';
import { MemoryRouter } from 'react-router-dom';
import { ToastContainer } from 'react-toastify';
import { Provider } from 'react-redux';
import { store, persistor } from 'store';
import { PersistGate } from 'redux-persist/integration/react';
import Routes from './routes/index';
import GlobalStyle from './styles/global';

const App = () => {
  return (
    <Provider store={store}>
      <PersistGate persistor={persistor}>
        <MemoryRouter>
          <Routes />

          <GlobalStyle />
          <ToastContainer autoClose={3000} />
        </MemoryRouter>
      </PersistGate>
    </Provider>
  );
};
export default App;
