import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  editArea: {
    '& form': {
      display: 'flex',
      justifyContent: 'space-around',
      width: 1000,

      '& p': {
        fontSize: '10px',
        color: '#808080'
      },
      '& input, select': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #E0E0E0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginRight: '30px',
        marginBottom: '15px',
        backgroundColor: '#fffff',
        padding: '0 15px',
        alignItems: 'center',
        '&:focus': {
          border: `1px solid #FFCD00 `,
          color: '#000'
        },
        '&::placeholder': {
          color: '#E0E0E0'
        }
      }
    }
  },
  root: {
    display: 'flex'
  },
  formControl: {
    margin: theme.spacing(3),
    maxHeight: '30vh'
  },
  paper: {
    // maxWidth: '300px',
    // width: '25%',
    maxHeight: '100vh',
    height: '150px',

    marginBottom: '20px',
    overflow: 'auto'
  },
  buttons: {
    display: 'flex',
    justifyContent: 'space-between',
    marginTop: '2%'
  },
  formLabels: {
    fontWeight: 'bold',
    color: '#808080',
    marginBottom: '10px'
  }
}));

export default useStyles;
