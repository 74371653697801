import { makeStyles } from '@material-ui/core/styles';

const useStyles = makeStyles(theme => ({
  modal: {
    display: 'flex',
    alignItems: 'center',
    justifyContent: 'center',
    backgroundColor: 'rgba(255,255,255,0.1)'
  },
  paper: {
    backgroundColor: theme.palette.background.paper,
    display: 'flex',
    flexDirection: 'column',

    alignItems: 'center',
    border: '1px solid #000',
    boxShadow: theme.shadows[1],
    padding: theme.spacing(3, 1, 1),
    maxWidth: '1200px',
    maxHeight: '700px',
    width: '80%',
    height: '90%',

    '& h2': {
      marginBottom: '10px',
      color: '#808080'
    }
  }
}));

export default useStyles;
