import axios from 'axios';

import { GET_BUCKETS } from '../types';

const getBucketsSuccess = buckets => {
  return {
    type: GET_BUCKETS,
    payload: { buckets }
  };
};

export const getBuckets = url => {
  return async dispatch => {
    const resp = await axios.get(url);
    dispatch(getBucketsSuccess(resp.data));
  };
};
