import axios from 'axios';
import { GET_TERMS, POST_TERMS } from '../types';

const postTermsSuccess = addTerms => {
  return {
    type: POST_TERMS,
    payload: { addTerms }
  };
};

export const postTerms = (url, obj) => {
  return async dispatch => {
    const resp = await axios.post(url, obj);
    dispatch(postTermsSuccess(resp.data));
  };
};

const getTermsSuccess = terms => {
  return {
    type: GET_TERMS,
    payload: { terms }
  };
};

export const getTerms = url => {
  return async dispatch => {
    const resp = await axios.get(url);
    dispatch(getTermsSuccess(resp.data));
  };
};
