/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect } from 'react';
import DataGrid, {
  Column,
  Scrolling,
  SearchPanel,
  TotalItem,
  Summary
} from 'devextreme-react/data-grid';
import axios from 'axios';
import { Loading } from '../../componentes';
import TransitionsModal from './Modal';
import { store } from '../../store/index';
import '../styles.css';

const Main = () => {
  const [users, setUsers] = useState([]);
  const [loading, setLoading] = useState(false);
  const { apiPath } = store.getState().auth;
  const style = {
    cell: {
      justifyContent: 'space-between',
      display: 'flex'
    },
    container: {
      display: 'flex',
      flexDirection: 'row',

      width: '99,9%',

      overflow: 'hidden'
    },
    grid: {
      marginTop: '5px',
      height: 'calc(100vh - 70px)'
    }
  };

  async function loadData() {
    setLoading(true);

    const response = await axios.get(
      `${apiPath}/safe/accessCtrl/users?limit=500`
    );
    setUsers(response.data.lines);
    setLoading(false);
  }

  useEffect(() => {
    loadData();
  }, [apiPath]);

  const renderCellName = ({ data }) => {
    return (
      <div style={style.cell}>
        <TransitionsModal
          action="edit"
          title="Edit user"
          addNewUser={setUsers}
          users={users}
          user={data}
          loadReload={loadData}
        />
        <p>{data.name}</p>
        <p />
      </div>
    );
  };

  return (
    <div style={style.container}>
      {loading ? (
        <Loading />
      ) : (
        <>
          <DataGrid
            rowAlternationEnabled
            style={style.grid}
            selection={{ mode: 'single' }}
            showBorders
            hoverStateEnabled
            dataSource={users}
          >
            <Summary>
              <TotalItem
                column="name"
                summaryType="custom"
                displayFormat={`Total: ${users.length}`}
              />
            </Summary>
            <Scrolling mode="infinite" />
            <SearchPanel
              visible
              highlightCaseSensitive
              width={240}
              placeholder="Search..."
            />
            <Column
              cellRender={renderCellName}
              alignment="center"
              dataField="name"
            />
            <Column dataField="email" alignment="center" />
            <Column alignment="center" dataField="partners" caption="Units" />
            <Column
              dataField="businessUnits"
              alignment="center"
              caption="Laboratories"
            />
            <Column alignment="center" dataField="profile" />
            <Column dataField="country" alignment="center" caption="Country" />
            <Column dataField="language" alignment="center" />
            <Column alignment="center" dataField="enabled" dataType="boolean" />
          </DataGrid>
          <div style={{ marginTop: '10px' }}>
            <TransitionsModal
              fontSize="large"
              addNewUser={setUsers}
              title="New user"
              users={users}
              action="add"
              loadReload={loadData}
            />
          </div>
        </>
      )}
    </div>
  );
};

export default Main;
