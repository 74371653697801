/* eslint-disable no-param-reassign */
import produce from 'immer';
import { GET_BUCKETS } from '../types';

const INITIAL_STATE = {
  buckets: null
};

const bucket = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case GET_BUCKETS: {
        draft.buckets = action.payload.buckets;
        break;
      }
      default:
        break;
    }
  });
};

export default bucket;
