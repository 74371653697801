import React from 'react';
import { makeStyles } from '@material-ui/core/styles';
import Modal from '@material-ui/core/Modal';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import ZoomInIcon from '@material-ui/icons/ZoomIn';
import { useSelector } from 'react-redux';
import InfoOutlinedIcon from '@material-ui/icons/InfoOutlined';
import CloseIcon from '@material-ui/icons/Close';
import AddCircleIcon from '@material-ui/icons/AddCircle';

export default function Modall({
  showCloseButton,
  children,
  icon,
  text,
  closeMenu,
  opened,
  openModal,
  setOpenModal
}) {
  const [open, setOpen] = React.useState(opened || false);

  const handleOpen = () => {
    setOpen(true);
    setOpenModal(true);
  };

  const handleClose = () => {
    setOpen(false);
    setOpenModal(false);
  };

  const useStyles = makeStyles(theme => ({
    modal: {
      display: 'flex',
      alignItems: 'center',
      justifyContent: 'center',
      marginLeft: 'auto'
    },
    paper: {
      backgroundColor: '#fff',
      color: '#000',
      border: '1px solid #cdcdcd',
      boxShadow: theme.shadows[5],
      padding: theme.spacing(2, 4, 3)
    },
    button: {
      cursor: 'pointer',
      fontSize: '35px',

      color: '#009FE3'
    }
  }));
  const classes = useStyles();
  const closeModal = () => {
    if (closeMenu) {
      closeMenu();
      handleClose();
    } else {
      handleClose();
    }
  };

  const Button = {
    zoom: <ZoomInIcon className={classes.button} onClick={handleOpen} />,
    add: <AddCircleIcon className={classes.button} onClick={handleOpen} />,
    about: (
      <InfoOutlinedIcon
        style={{ color: '#fff' }}
        fontSize="large"
        className={classes.button}
        onClick={handleOpen}
      />
    )
  };

  return (
    <div>
      {icon && Button[icon]}
      {text && <small onClick={handleOpen}>{text}</small>}
      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={openModal}
        onClose={() => setOpenModal(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={openModal}>
          <span>
            {showCloseButton && (
              <CloseIcon
                onClick={closeModal}
                style={{
                  float: 'right',
                  cursor: 'pointer',
                  color: '#000'
                }}
                fontSize="large"
              />
            )}
            <div className={classes.paper}>{children}</div>
          </span>
        </Fade>
      </Modal>
    </div>
  );
}
