import React, { useState } from 'react';
import Modal from '@material-ui/core/Modal';
import EditIcon from '@material-ui/icons/Edit';
import AddCircleIcon from '@material-ui/icons/AddCircle';
import Backdrop from '@material-ui/core/Backdrop';
import Fade from '@material-ui/core/Fade';
import FormUser from '../FormUser';
import useStyles from './styled';

export default function TransitionsModal({
  addNewUser,
  title,
  users,
  user = null,
  action,
  loadReload
}) {
  const classes = useStyles();
  const [open, setOpen] = useState(false);

  return (
    <div>
      {action === 'add' && (
        <AddCircleIcon
          style={{ cursor: 'pointer', color: '#1d4f65' }}
          fontSize="large"
          onClick={() => setOpen(!open)}
        />
      )}
      {action === 'edit' && (
        <EditIcon
          style={{ cursor: 'pointer', color: '#1d4f65' }}
          fontSize="small"
          onClick={() => setOpen(!open)}
        />
      )}

      <Modal
        aria-labelledby="transition-modal-title"
        aria-describedby="transition-modal-description"
        className={classes.modal}
        open={open}
        onClose={() => setOpen(false)}
        closeAfterTransition
        BackdropComponent={Backdrop}
        BackdropProps={{
          timeout: 500
        }}
      >
        <Fade in={open}>
          <div className={classes.paper}>
            <h2>{title}</h2>
            <FormUser
              setOpen={setOpen}
              action={action}
              user={user}
              users={users}
              addNewUser={addNewUser}
              loadReload={loadReload}
            />
          </div>
        </Fade>
      </Modal>
    </div>
  );
}
