/* eslint-disable no-empty */
import React, { useState, useEffect, useRef } from 'react';
import axios from 'axios';
import FormLabel from '@material-ui/core/FormLabel';
import FormControl from '@material-ui/core/FormControl';
import FormGroup from '@material-ui/core/FormGroup';
import FormControlLabel from '@material-ui/core/FormControlLabel';
import Paper from '@material-ui/core/Paper';
import CheckBoxIcon from '@material-ui/icons/CheckBox';
import Checkbox from '@material-ui/core/Checkbox';
import Button from '@material-ui/core/Button';
import { toast } from 'react-toastify';

import { Loading } from 'componentes';
import useStyles from './styles';
import { store } from '../../../store/index';

function CheckboxesGroup({
  idPartners,
  idModules,
  idBusinessUnits,
  setIdPartners,
  setIdModules,
  setIdBusinessUnits
}) {
  const { apiPath } = store.getState().auth;
  const classes = useStyles();
  const [businessUnits, setBusinessUnits] = useState([]);
  const [modules, setModules] = useState([]);
  const [partners, setPartners] = useState([]);

  useEffect(() => {
    async function loadData() {
      const bu = await axios.get(`${apiPath}/safe/businessUnits`);
      const mo = await axios.get(`${apiPath}/safe/modules`);
      const pa = await axios.get(`${apiPath}/safe/partners`);
      setBusinessUnits(bu.data.lines);
      setModules(mo.data.lines);
      setPartners(pa.data.lines);
    }
    loadData();
  }, [apiPath]);

  const addPartners = u => {
    const retPart = idPartners.find(part => part === u.id);
    if (retPart) {
      const newPartner = idPartners.filter(part => part !== u.id);
      setIdPartners(newPartner);
    } else {
      setIdPartners([...idPartners, u.id]);
    }
  };

  const addBusinessUnit = u => {
    const retUnit = idBusinessUnits.find(unit => unit === u.id);
    if (retUnit) {
      const newUnit = idBusinessUnits.filter(unit => unit !== u.id);
      setIdBusinessUnits(newUnit);
    } else {
      setIdBusinessUnits([...idBusinessUnits, u.id]);
    }
  };

  const addResource = r => {
    const retRes = idModules.find(res => res === r.id);
    if (retRes) {
      const newRes = idModules.filter(res => res !== r.id);
      setIdModules(newRes);
    } else {
      setIdModules([...idModules, r.id]);
    }
  };

  if (!partners.length || !modules.length || !businessUnits.length) {
    return <Loading />;
  }

  return (
    <>
      {/* <div className={classes.root}> */}
      <Paper className={classes.paper}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.formLabels} component="legend">
            Partners
          </FormLabel>

          <FormGroup>
            {partners.map(part => {
              return (
                <FormControlLabel
                  style={{ color: '#c0c0c0' }}
                  control={
                    <Checkbox
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      onChange={() => addPartners(part)}
                      style={{
                        color: '#009FE3',
                        height: '12px',
                        marginTop: '10px'
                      }}
                      checked={idPartners.find(u => u === part.id)}
                      name={part.name}
                    />
                  }
                  label={part.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Paper>

      <Paper className={classes.paper}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.formLabels} component="legend">
            Laboratories
          </FormLabel>

          <FormGroup>
            {businessUnits.map(busUnit => {
              return (
                <FormControlLabel
                  style={{ color: '#c0c0c0' }}
                  control={
                    <Checkbox
                      checkedIcon={<CheckBoxIcon fontSize="small" />}
                      onChange={() => addBusinessUnit(busUnit)}
                      style={{
                        color: '#009FE3',
                        height: '12px',
                        marginTop: '10px'
                      }}
                      checked={idBusinessUnits.find(u => u === busUnit.id)}
                      name={busUnit.name}
                    />
                  }
                  label={busUnit.name}
                />
              );
            })}
          </FormGroup>
        </FormControl>
      </Paper>

      <Paper className={classes.paper}>
        <FormControl component="fieldset" className={classes.formControl}>
          <FormLabel className={classes.formLabels} component="legend">
            Resources
          </FormLabel>
          <FormGroup>
            {modules.map(resource => (
              <FormControlLabel
                style={{ color: '#c0c0c0' }}
                control={
                  <Checkbox
                    checkedIcon={<CheckBoxIcon fontSize="small" />}
                    onChange={() => addResource(resource)}
                    style={{
                      color: '#009FE3',
                      height: '12px',
                      marginTop: '10px'
                    }}
                    checked={idModules.find(r => r === resource.id)}
                    name={resource.name}
                  />
                }
                label={resource.name}
              />
            ))}
          </FormGroup>
        </FormControl>
      </Paper>
      {/* </div> */}
    </>
  );
}

const FormUser = ({
  setOpen,
  action,
  user = null,
  users,
  addNewUser,
  loadReload
}) => {
  const { apiPath } = store.getState().auth;
  // console.log(user);
  const [name, setName] = useState(user ? user.name : '');
  const [email, setEmail] = useState(user ? user.email : '');
  const [cellular, setCellular] = useState(user ? user.cellular : '');
  const [enabled, setEnabled] = useState(user ? user.enabled : true);
  const [idProfile, setIdProfile] = useState(user ? user.idProfile : null);
  const [idLanguage, setIdLanguage] = useState(user ? user.idLanguage : null);
  const [idCountry, setIdCountry] = useState(user ? user.idCountry : null);
  const [originPlace, setOriginPlace] = useState(
    user ? user.originPlace : null
  );
  const [idPartners, setIdPartners] = useState(user ? user.idPartners : []);
  const [idModules, setIdModules] = useState(user ? user.idModules : []);
  const [idBusinessUnits, setIdBusinessUnits] = useState(
    user ? user.idBusinessUnits : []
  );

  const [languages, setLanguages] = useState([]);
  const [profiles, setProfiles] = useState([]);
  const [countries, setCountries] = useState([]);

  const emailRef = useRef();
  const nameRef = useRef();
  const formRef = useRef(null);

  useEffect(() => {
    async function loadData() {
      const [c, p, l] = await Promise.all([
        axios.get(`${apiPath}/safe/countries`),
        axios.get(`${apiPath}/safe/accessCtrl/profiles`),
        axios.get(`${apiPath}/safe/localization`)
      ]);

      setLanguages(l.data.lines);
      setProfiles(p.data.lines);
      setCountries(c.data.lines);
    }
    loadData();
  }, [apiPath]);

  const handleSubmit = async e => {
    e.preventDefault();
    const st = await axios.get(
      `${apiPath}/status?hostname=${window.location.hostname}`
    );

    if (!name) {
      nameRef.current.focus();
      return toast.error('Fill in the name');
    }

    if (!email) {
      emailRef.current.focus();
      return toast.error('Fill in the email');
    }

    // if (idPartners.length === 0)
    //   return toast.error('Fill at least one partner');

    // if (idBusinessUnits.length === 0)
    //   return toast.error('Fill at least one business unit');

    // if (idModules.length === 0)
    //   return toast.error('Fill at least one resource');

    const User = {
      url:
        st.data.front.env === 'development'
          ? 'https://dsm.allims.com.br'
          : 'https://servicecatalogue.dsm.com',
      name,
      email: email.toLowerCase(),
      cellular,
      idProfile,
      idLanguage,
      enabled,
      originPlace,
      idCountry,
      partners: idPartners,
      modules: idModules,
      businessUnits: idBusinessUnits
    };

    try {
      if (action === 'add') {
        const response = await axios.post(
          `${apiPath}/safe/accessCtrl/users`,
          User
        );
        if (response.data.success) {
          toast.success('User added successfully');
          setOpen(false);
          loadReload();
        } else {
          toast.error(response.data.message);
        }
      }

      if (action === 'edit') {
        const response = await axios.put(
          `${apiPath}/safe/accessCtrl/user/${user.id}`,
          User
        );
        if (response.data.success) {
          toast.success('Updated profile!');
          setOpen(false);
          loadReload();
        } else {
          toast.error(response.data.message);
        }
      }
    } catch (error) {}
  };

  const classes = useStyles();
  return (
    <div className={classes.editArea}>
      <form ref={formRef} onSubmit={handleSubmit}>
        <div style={{ width: '50%', paddingRight: '40px' }} id="group-1">
          <div>
            <p>Name</p>
            <input
              placeholder="Name"
              ref={nameRef}
              onChange={e => setName(e.target.value)}
              value={name}
            />
          </div>
          <div>
            <p>Email</p>
            <input
              placeholder="Email"
              ref={emailRef}
              disabled={action === 'edit'}
              onChange={e => setEmail(e.target.value)}
              value={email}
            />
          </div>
          <div>
            <p> Country </p>
            <select
              placeholder="Country"
              value={idCountry}
              onChange={e => setIdCountry(e.target.value)}
            >
              {countries.map(c => (
                <option value={c.id}>{c.name}</option>
              ))}
            </select>
          </div>
          <div>
            <p>Telephone</p>
            <input
              placeholder="Telephone"
              onChange={e => setCellular(e.target.value)}
              value={cellular}
            />
          </div>
          <div>
            <p>Origin place</p>
            <input
              placeholder="Origin Place"
              onChange={e => setOriginPlace(e.target.value)}
              value={originPlace}
            />
          </div>
          <div>
            <p>Language</p>
            <select
              placeholder="Language"
              onChange={e => setIdLanguage(e.target.value)}
              value={idLanguage}
            >
              {languages.map(l => (
                <option value={l.id}>{l.name}</option>
              ))}
            </select>
          </div>
          <div>
            <p>Profile</p>
            <select
              placeholder="Profile"
              onChange={e => setIdProfile(e.target.value)}
              value={idProfile}
            >
              {profiles.map(l => (
                <option value={l.id}>{l.name}</option>
              ))}
            </select>
          </div>
        </div>
        <div style={{ width: '50%' }} id="group-2">
          <CheckboxesGroup
            idPartners={idPartners}
            idModules={idModules}
            idBusinessUnits={idBusinessUnits}
            setIdPartners={setIdPartners}
            setIdModules={setIdModules}
            setIdBusinessUnits={setIdBusinessUnits}
          />
        </div>
      </form>

      <div className={classes.buttons}>
        <>
          {enabled ? (
            <div style={{ width: '360px', display: 'flex' }}>
              <Button
                onClick={() => setEnabled(!enabled)}
                variant="contained"
                color="secondary"
                fullWidth
              >
                Disable{' '}
              </Button>
              <Button
                style={{ marginLeft: '5px' }}
                onClick={() => setOpen(false)}
                fullWidth
              >
                Cancel
              </Button>
            </div>
          ) : (
            <div style={{ width: '360px', display: 'flex' }}>
              <Button
                onClick={() => setEnabled(!enabled)}
                variant="contained"
                color="primary"
                fullWidth
              >
                Enable{' '}
              </Button>
              <Button
                style={{ marginLeft: '5px' }}
                onClick={() => setOpen(false)}
                fullWidth
              >
                Cancel
              </Button>
            </div>
          )}
          <div style={{ width: '180px' }}>
            <Button
              style={{ background: '#45AC34' }}
              onClick={e => handleSubmit(e)}
              variant="contained"
              color="primary"
              fullWidth
            >
              Save{' '}
            </Button>
          </div>
        </>
      </div>
    </div>
  );
};

export default FormUser;
