export const SIGN_IN_REQUEST = '@auth/SIGN_IN_REQUEST';
export const SIGN_IN_SUCCESS = '@auth/SIGN_IN_SUCCESS';
export const SIGN_FAILURE = '@auth/SIGN_FAILURE';
export const SIGN_OUT = '@auth/SIGN_OUT';
export const SET_TYPE = 'SET_TYPE';
export const GET_TERMS = 'GET_TERMS';
export const POST_TERMS = 'POST_TERMS';
export const GET_BUCKETS = 'GET_BUCKETS';
export const SET_NORMALIZED = 'SET_NORMALIZED';
export const SET_PARAMS = 'SET_PARAMS';
export const GET_DATABASE = 'GET_DATABASE';
export const GET_DATA_TYPE = 'GET_DATA_TYPE';
export const GET_DATA_MAIN = 'GET_DATA_MAIN';
export const GET_DATA_NORM = 'GET_DATA_NORM';
export const GET_DATA_NORM_FILTER = 'GET_DATA_NORM_FILTER';
export const POST_DATA_TYPE = 'POST_DATA_TYPE';
export const POST_DATA_MAIN = 'POST_DATA_MAIN';
export const POST_DATA_NORM = 'POST_DATA_NORM';
export const PUT_REPORTS = 'PUT_REPORTS';
export const PUT_REPORTS_FOLDERS = 'PUT_REPORTS_FOLDERS';
export const POST_REPORTS_FOLDERS = 'POST_REPORTS_FOLDERS';
export const PUT_DATA_MAIN = 'PUT_DATA_MAIN';
export const SET_BUSINESS_UNIT = 'SET_BUSINESS_UNIT';
export const GET_DATA_UNIT = 'GET_DATA_UNIT';
export const SET_CLICK_OK = 'SET_CLICK_OK';
export const UPDATE_DATA_GRID = 'UPDATE_DATA_GRID';
export const GET_DATA_NORM_MERGED = 'GET_DATA_NORM_MERGED';
export const IS_LOADING = 'IS_LOADING';
export const GET_FOLDER_PDF = 'GET_FOLDER_PDF';
export const SET_SQL = 'SET_SQL';
export const IS_LOADING_TAB = 'IS_LOADING_TAB';
export const SET_CSS = 'SET_CSS';
export const SET_JS = 'SET_JS';
export const SET_HEAD = 'SET_HEAD';
export const SET_BODY = 'SET_BODY';
export const SET_RED_PARAMS = 'SET_RED_PARAMS';
export const SET_READ_ONLY_MODE = 'SET_READ_ONLY_MODE';
export const PUT_PDF = 'PUT_PDF';
export const GET_SNIPPETS = 'GET_SNIPPETS';
export const GET_PDF = 'GET_PDF';
export const POST_JSON = 'POST_JSON';
export const SET_OPEN_MENU_STUDIO = 'SET_OPEN_MENU_STUDIO';
export const DEL_FOLDER = 'DEL_FOLDER';
export const POST_REPORTS = 'POST_REPORTS';
export const SET_PDF_OPTIONS = 'SET_PDF_OPTIONS';
export const SET_TITLE = 'SET_TITLE';
export const SET_UNSAVE = 'SET_UNSAVE';
export const SET_USER_PROPS = 'SET_USER_PROPS';
export const SET_USER_PROPS_VALUE = 'SET_USER_PROPS_VALUE';
export const SET_READ_ONLY_ACE = 'SET_READ_ONLY_ACE';

export const PUT_REPORTS_VERSION = 'PUT_REPORTS_VERSION';
export const POST_REPORTS_VERSION = 'POST_REPORTS_VERSION';
export const GET_PDF_VERSION = 'GET_PDF_VERSION';
export const SET_CURRENT_VERSION = 'SET_CURRENT_VERSION';
