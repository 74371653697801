/* eslint-disable no-use-before-define */
/* eslint-disable import/no-unresolved */
/* eslint-disable jsx-a11y/iframe-has-title */
import React, { useState, useEffect, useRef } from 'react';
import DataGrid, {
  Column,
  Selection,
  TotalItem,
  Summary,
  Scrolling
} from 'devextreme-react/data-grid';
import { useSelector, useDispatch } from 'react-redux';
import {
  Dialog,
  DialogTitle,
  DialogContent,
  DialogActions,
  Button,
  TextField
} from '@material-ui/core';
import ClearIcon from '@material-ui/icons/Clear';
import AddIcon from '@material-ui/icons/Add';
import mergeIcon from 'assets/icons/merge.png';
import mergeDisabledIcon from 'assets/icons/mergeDisabled.png';

import DeleteIcon from '@material-ui/icons/Delete';
import { toast } from 'react-toastify';
import Autocomplete, {
  createFilterOptions
} from '@material-ui/lab/Autocomplete';
import {
  getType,
  getMain,
  getNorm,
  getNormMerged,
  updateDataGrid,
  postNorm,
  postMain,
  putMain,
  getDataUnit,
  setClickOkSearch
} from 'store/modules/normalizer/actions';
import { Modal, Loading, Toolpit } from 'componentes';
import { store } from 'store/index';
import { useStyles } from './styles';
import './styles.css';

const filter = createFilterOptions();

const Normalizer = () => {
  const classes = useStyles();
  const dispatch = useDispatch();
  const { apiPath } = store.getState().auth;
  const {
    type,
    normalized,
    dataNorm,
    businessUnit,
    dataType,
    dataMain,
    normPost,
    mainPost,
    mainPut,
    clickOkSearch
  } = useSelector(state => state.normalizer);
  const [objList, setObjList] = useState([]);
  const [value, setValue] = useState(null);
  const [valueInput, setValueInput] = useState();
  const [open, toggleOpen] = useState(false);
  const [showModal, setShowModal] = useState(false);
  const [rowsSelected, setRowsSelected] = useState([]);
  const [copyRowsSelected, setCopyRowsSelected] = useState([]);
  const [aMerge, setAMerge] = useState(false);
  const [aDesn, setADesn] = useState(false);
  const [searchNormalizer, setSearchNormalizer] = useState('');
  const [typeSel, setTypeSel] = useState(type);
  const [dialogValue, setDialogValue] = useState({
    id: 0,
    name: '',
    translations: []
  });
  const [loading, setLoading] = useState(false);
  const dataGridRef = useRef();
  const ptInputRef = useRef();
  const espInputRef = useRef();
  const engInputRef = useRef();

  useEffect(() => {
    dispatch(getType(`${apiPath}/safe/normalizer/types`));
    dispatch(getDataUnit(`${apiPath}/safe/normalizer/businessUnit`));
  }, []);

  useEffect(() => {
    dispatch(
      getMain(`${apiPath}/safe/normalizer/main?type=${type}&limit=5000&page=1`)
    );
  }, [type, normalized, mainPost, mainPut]);

  useEffect(() => {
    const idSelect =
      dataMain &&
      dataMain.lines.filter(v => {
        if (v.name === dialogValue.name) return v;
      });
    if (idSelect) {
      onChanceAuto('', idSelect[0]);
    }
  }, [dataMain]);

  useEffect(() => {
    setLoading(true);

    dispatch(
      getNorm(
        `${apiPath}/safe/normalizer?type=${type}&normalized=${normalized}&limit=5000&page=1`
      )
    );
    setLoading(false);
  }, []);

  useEffect(() => {
    if (clickOkSearch) {
      let objBU = '';
      businessUnit.map((v, i) => {
        if (i === 0) {
          objBU = v.id;
        } else {
          objBU += `%2C${v.id}`;
        }
      });

      setLoading(true);
      dispatch(setClickOkSearch(false));

      dispatch(
        getNormMerged(
          `${apiPath}/safe/normalizer?search=${searchNormalizer}&type=${type}&normalized=${normalized}&businessUnits=${objBU}&limit=5000&page=1`,
          copyRowsSelected
        )
      );

      setLoading(false);
    }
  }, [searchNormalizer, clickOkSearch, normPost, type, normalized]);

  useEffect(() => {
    if (rowsSelected.length === 1) {
      setValue('');
      setDialogValue('');
      setValueInput('');
    }
    const tm = rowsSelected.filter(v => {
      if (v.translations.length > 0) return v;
    });
    if (rowsSelected.length > 0) setAMerge(false);
    if (tm.length > 0) {
      const differentIdMain = tm.find(row => row.idMain !== tm[0].idMain);
      if (differentIdMain) {
        setAMerge(false);
      }

      if (!differentIdMain && rowsSelected.length > 1) {
        setAMerge(true);
      }
    } else {
      setValue(null);
    }

    const td = rowsSelected.filter(v => {
      if (v.translations.length > 0) return v;
    });

    const tds = rowsSelected.filter(v => {
      if (v.translations.length === 0) return v;
    });

    if (td && td[0]) {
      const teds =
        dataMain &&
        dataMain.lines.filter(v => {
          if (v.name === td[0].nameMain) return v;
        });

      if (teds && teds[0]) {
        setValue(teds[0]);
        onChanceAuto('', teds[0]);
      }
    }
    if (
      td[0] &&
      td[0].translations &&
      td[0].translations.length > 0 &&
      tds.length === 0 &&
      rowsSelected.length > 0
    )
      setADesn(true);
    else setADesn(false);

    setObjList([]);

    rowsSelected.map(v => {
      const objL = {
        idBusinessUnit: v.idBusinessUnit,
        idLocal: v.idLocal
      };
      setObjList(prevState => [...prevState, objL]);
    });
  }, [rowsSelected]);

  const renderTranslate = (data, language) => {
    if (data.translations.length > 0) {
      return data.translations.find(t => t.language === language).translation;
    }
  };

  const onClickMerge = e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);

    const idSel = rowsSelected.filter(v => {
      if (v.translations.length > 0) return v.idMain;
    });

    const obj = {
      idMain: idSel[0].idMain,
      type,
      list: objList
    };

    dispatch(
      updateDataGrid(rowsSelected, idSel[0].translations, idSel[0].idMain, true)
    );
    dispatch(postNorm(`${apiPath}/safe/normalizer`, obj));
  };

  const onClickDesn = e => {
    e.preventDefault();
    setLoading(true);
    setTimeout(() => setLoading(false), 2000);

    dispatch(updateDataGrid(rowsSelected, [], 0, false));
    dispatch(
      postNorm(`${apiPath}/safe/normalizer`, {
        idMain: 0,
        type,
        list: objList
      })
    );
  };

  const handleSubmitMain = e => {
    e.preventDefault();

    if (typeSel !== '') {
      setValueInput();
      dispatch(
        postMain(`${apiPath}/safe/normalizer/main`, {
          name: dialogValue.name,
          type: typeSel,
          translations: {
            'pt-BR': '',
            'es-ES': '',
            'en-US': ''
          }
        })
      );
    }
    setDialogValue({
      id: 0,
      name: dialogValue.name,
      translations: []
    });

    toggleOpen(false);
  };
  const rendeCellUnits = () => {
    const units = [];
    if (businessUnit.length > 0) {
      businessUnit.map(unit => units.push(unit.name));
      return units.toString();
    }
    return 'All';
  };

  const handleSubmitNorm = e => {
    e.preventDefault();
    if (!valueInput.es) {
      espInputRef.current.focus();
      return toast.error('Fill in all fields ');
    }
    if (!valueInput.pt) {
      ptInputRef.current.focus();
      return toast.error('Fill in all fields');
    }

    if (!valueInput.en) {
      engInputRef.current.focus();
      return toast.error('Fill in all fields');
    }
    const list = rowsSelected.map(v => ({
      idBusinessUnit: v.idBusinessUnit,
      idLocal: v.idLocal
    }));

    const obj = {
      name: dialogValue.name,
      translations: {
        'en-US': valueInput.en,
        'es-ES': valueInput.es,
        'pt-BR': valueInput.pt
      }
    };

    dispatch(putMain(`${apiPath}/safe/normalizer/main/${dialogValue.id}`, obj));
    const translate = [
      { language: 'en-US', translation: valueInput.en },
      { language: 'es-ES', translation: valueInput.es },
      { language: 'pt-BR', translation: valueInput.pt }
    ];

    dispatch(updateDataGrid(rowsSelected, translate, dialogValue.id, true));
    const updatedRowSelect = [
      {
        idBusinessUnit: rowsSelected[0].idBusinessUnit,
        businessUnit: rowsSelected[0].businessUnit,
        idLocal: rowsSelected[0].idLocal,
        nameLocal: rowsSelected[0].nameLocal,
        normalized: true,
        idMain: dialogValue.id,
        nameMain: dialogValue.name,
        translations: translate
      }
    ];

    setRowsSelected(updatedRowSelect);

    const objNo = {
      idMain: dialogValue.id,
      type: typeSel,
      list
    };
    dispatch(postNorm(`${apiPath}/safe/normalizer`, objNo));

    setShowModal(false);
  };

  const onChanceAuto = (e, newValue) => {
    if (typeof newValue === 'string') {
      setValue(newValue.name);
      setTimeout(() => {
        toggleOpen(true);

        setDialogValue({
          id: 0,
          name: newValue.name,
          translations: []
        });
      });
    } else if (newValue && newValue.inputValue) {
      toggleOpen(true);
      setValue(newValue.inputValue);
      setDialogValue({
        id: 0,
        name: newValue.inputValue,
        translations: []
      });
    } else if (newValue && newValue.id) {
      setValue(newValue.name);
      setDialogValue({
        id: newValue.id,
        name: newValue.name,
        translations: newValue.translations
      });

      setValueInput({
        es: newValue.translations.find(t => Object.keys(t)[0] === 'es-ES')[
          'es-ES'
        ],
        pt: newValue.translations.find(t => Object.keys(t)[0] === 'pt-BR')[
          'pt-BR'
        ],
        en: newValue.translations.find(t => Object.keys(t)[0] === 'en-US')[
          'en-US'
        ]
      });
    }
  };

  const onKeyEnt = e => {
    if (e.keyCode === 13) {
      setCopyRowsSelected(rowsSelected);
      dispatch(setClickOkSearch(true));
    }
  };

  const clearGrid = e => {
    dataGridRef.current.instance.deselectRows([rowsSelected.pop().idLocal]);
    setRowsSelected(rowsSelected.slice(0, -1));
  };

  const openModalNormalizer = e => {
    setShowModal(true);

    const translationFill = rowsSelected.filter(v => {
      if (v.translations.length > 0) return v;
    });
    if (translationFill.length > 0) {
      const differentIdMain = translationFill.find(
        row => row.idMain !== translationFill[0].idMain
      );
      if (differentIdMain) {
        setValueInput();
        setDialogValue({ id: 0, name: '', translations: [] });
        setValue();
      }

      if (!differentIdMain) {
        setValueInput({
          en: translationFill[0].translations.find(t => t.language === 'en-US')
            .translation,
          es: translationFill[0].translations.find(t => t.language === 'es-ES')
            .translation,

          pt: translationFill[0].translations.find(t => t.language === 'pt-BR')
            .translation
        });

        setValue(translationFill[0].nameMain);
      }
    }
  };

  if (dataNorm) {
    return (
      <div>
        <div className={classes.grid}>
          <div className={classes.buttons}>
            <div>
              <Toolpit title="Clear" placement="bottom">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={rowsSelected.length === 0}
                  onClick={() => clearGrid()}
                >
                  <ClearIcon />
                </Button>
              </Toolpit>

              <Toolpit title="Normalize" placement="bottom">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={rowsSelected.length === 0}
                  onClick={() => openModalNormalizer()}
                >
                  <AddIcon />
                </Button>
              </Toolpit>
              <Toolpit title="Desnormalize" placement="bottom">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!aDesn}
                  onClick={onClickDesn}
                >
                  <DeleteIcon />
                </Button>
              </Toolpit>
              <Toolpit title="Merge" placement="bottom">
                <Button
                  variant="contained"
                  color="primary"
                  disabled={!aMerge}
                  onClick={onClickMerge}
                >
                  <img
                    style={{ width: '25px' }}
                    src={!aMerge ? mergeDisabledIcon : mergeIcon}
                  />
                </Button>
              </Toolpit>
            </div>
            <div
              className={classes.searchBox}
              style={{ display: 'flex', height: 20, marginRight: 10 }}
            >
              <input
                placeholder="Search..."
                value={searchNormalizer}
                onChange={e => setSearchNormalizer(e.target.value)}
                onKeyDown={onKeyEnt}
              />
              <Button
                onClick={e => {
                  setCopyRowsSelected(rowsSelected);
                  dispatch(setClickOkSearch(true));
                }}
                color="primary"
              >
                Ok
              </Button>
            </div>
          </div>
          {loading && <Loading />}

          <DataGrid
            keyExpr="idLocal"
            ref={dataGridRef}
            dataSource={dataNorm.lines}
            rowAlternationEnabled
            style={{ height: 'calc(100vh - 109px)' }}
            showBorders
            remoteOperations
            onSelectionChanged={e => setRowsSelected(e.selectedRowsData)}
            allowColumnResizing
            autoNavigateToFocusedRow
            columnResizingMode="widget"
          >
            <Summary>
              <TotalItem
                showInColumn="translations[2].translation"
                summaryType="custom"
                alignment="right"
                displayFormat={`Selected: ${rowsSelected.length}/${dataNorm &&
                  dataNorm.lines &&
                  dataNorm.lines.length}`}
              />

              <TotalItem
                alignment="right"
                showInColumn="nameLocal"
                summaryType="custom"
                displayFormat={`Type: ${type
                  .substring(0, 1)
                  .toUpperCase()
                  .concat(type.substring(1))}`}
              />
              <TotalItem
                showInColumn="businessUnit"
                summaryType="custom"
                displayFormat={`Untis: ${rendeCellUnits()}`}
              />

              <TotalItem
                showInColumn="normalized"
                summaryType="custom"
                displayFormat={`Normalized: ${
                  normalized === 'all'
                    ? 'All'
                    : normalized === 'true'
                    ? 'Yes'
                    : 'No'
                }`}
              />
            </Summary>
            <Scrolling mode="infinite" />
            <Selection
              mode="multiple"
              selectAllMode="allPages"
              showCheckBoxesMode="onClick"
            />

            <Column
              caption="Original Description"
              dataField="nameLocal"
              alignment="left"
            />
            <Column dataField="businessUnit" alignment="left" />
            <Column dataField="normalized" alignment="center" />
            <Column
              caption="English"
              cellRender={({ data }) => renderTranslate(data, 'en-US')}
              alignment="left"
            />
            <Column
              caption="Spanish"
              cellRender={({ data }) => renderTranslate(data, 'es-ES')}
              alignment="left"
            />
            <Column
              dataField="translations[2].translation"
              caption="Portuguese"
              cellRender={({ data }) => renderTranslate(data, 'pt-BR')}
              alignment="left"
            />
          </DataGrid>
        </div>
        <Modal openModal={showModal} setOpenModal={setShowModal}>
          <div style={{ width: '600px' }}>
            <div className={classes.add}>
              <>
                <small>Original description</small>

                <Autocomplete
                  value={value}
                  onChange={(e, newValue) => onChanceAuto(e, newValue)}
                  filterOptions={(options, params) => {
                    const filtered = filter(options, params);

                    if (params.inputValue !== '') {
                      filtered.push({
                        inputValue: params.inputValue,
                        name: `Add "${params.inputValue}"`
                      });
                    }

                    return filtered;
                  }}
                  id="free-solo-dialog-demo"
                  options={dataMain && dataMain.lines}
                  getOptionLabel={option => {
                    if (typeof option === 'string') {
                      return option;
                    }
                    if (option.inputValue) {
                      return option.inputValue;
                    }
                    return option.name;
                  }}
                  selectOnFocus
                  clearOnBlur
                  handleHomeEndKeys
                  renderOption={option => option.name}
                  freeSolo
                  renderInput={params => (
                    <TextField
                      id="originalDescription"
                      className={classes.input}
                      {...params}
                      variant="outlined"
                    />
                  )}
                />
                <Dialog
                  open={open}
                  onClose={e => toggleOpen(false)}
                  aria-labelledby="form-dialog-title"
                >
                  <form onSubmit={handleSubmitMain}>
                    <DialogTitle id="form-dialog-title">Add a new</DialogTitle>
                    <DialogContent style={{ marginTop: -10 }}>
                      <TextField
                        autoFocus
                        margin="dense"
                        id="name"
                        value={dialogValue.name}
                        onChange={event => {
                          setDialogValue({
                            id: 0,
                            name: event.target.value,
                            translations: []
                          });
                        }}
                        label="Name"
                        type="text"
                      />
                    </DialogContent>
                    <DialogActions>
                      <Button onClick={e => toggleOpen(false)} color="primary">
                        Cancel
                      </Button>
                      <Button type="submit" color="primary">
                        Add
                      </Button>
                    </DialogActions>
                  </form>
                </Dialog>
              </>
            </div>

            <div className={classes.modalContent}>
              <div>
                <small>Type</small>
                <select
                  value={typeSel}
                  onChange={e => setTypeSel(e.target.value)}
                  style={{ width: '100%' }}
                >
                  {dataType &&
                    dataType.lines.map(v => (
                      <option value={v.code}>{v.name}</option>
                    ))}
                </select>
              </div>
              <div>
                <small>Spanish</small>
                <input
                  ref={espInputRef}
                  value={valueInput && valueInput.es}
                  onChange={e =>
                    setValueInput({ ...valueInput, es: e.target.value })
                  }
                />
              </div>
              <div>
                <small>Portuguese</small>
                <input
                  ref={ptInputRef}
                  value={valueInput && valueInput.pt}
                  onChange={e =>
                    setValueInput({ ...valueInput, pt: e.target.value })
                  }
                />
              </div>
              <div>
                <small>English</small>
                <input
                  ref={engInputRef}
                  value={valueInput && valueInput.en}
                  onChange={e =>
                    setValueInput({ ...valueInput, en: e.target.value })
                  }
                />
              </div>

              <div className={classes.buttonsModal}>
                <Button
                  fullWidth
                  onClick={() => {
                    setShowModal(false);
                    setValueInput();
                    setValue();
                    setDialogValue({ id: 0, name: '', translations: [] });
                  }}
                >
                  Cancel
                </Button>
                <Button
                  variant="contained"
                  style={{ background: '#009FE3', color: '#fff' }}
                  fullWidth
                  disabled={
                    !valueInput ||
                    (valueInput.es === '' &&
                      valueInput.pt === '' &&
                      valueInput.en === '')
                  }
                  onClick={handleSubmitNorm}
                >
                  OK
                </Button>
              </div>
            </div>
          </div>
        </Modal>
      </div>
    );
  }

  return <Loading />;
};

export default Normalizer;
