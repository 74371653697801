import { makeStyles } from '@material-ui/core/styles';

const imgLogin = require('assets/images/signin-default.jpg');

export const useStyles = makeStyles(theme => ({
  root: {
    height: '100vh'
  },
  image: {
    backgroundImage: `url(${imgLogin})`,
    backgroundRepeat: 'no-repeat',
    backgroundSize: 'cover',
    backgroundPosition: 'center'
  },
  paper: {
    margin: theme.spacing(5, 4),
    display: signed => (signed ? 'none' : 'flex'),
    flexDirection: 'column',
    alignItems: 'center'
  },
  loading: {
    display: 'flex',
    margin: theme.spacing(50, 4),
    justifyContent: 'center',
    alignItems: 'center'
  },

  errors: {
    marginTop: 0,
    color: 'red'
  },

  BusinessScreen: {
    margin: theme.spacing(5, 4),
    display: signed => (signed ? 'flex' : 'none'),
    flexDirection: 'column',
    alignItems: 'center'
  },
  form: {
    width: '100%',
    marginTop: theme.spacing(0)
  },
  submit: {
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#FFCD00',
    color: '#F0F0F0',
    '&:hover': {
      backgroundColor: '#FFCD00',
      opacity: '10'
    }
  },
  version: {
    color: '#e0e0e0',
    fontSize: '10px',
    float: 'right',
    cursor: 'pointer'
  },

  input: {
    width: '100%',
    height: '36px',
    fontSize: '14px',
    border: `1px solid #F0F0F0`,
    borderRadius: '5px',
    outline: 0,
    transition: 'all ease .4s',
    marginBottom: '15px',
    backgroundColor: '#fff',

    '&:focus': {
      border: `1px solid #FFCD00`,
      color: '#000'
    }
  },
  inputDisabled: {
    width: '100%',
    height: '36px',
    padding: '5px',
    border: `1px solid #FFCD00`,
    borderRadius: '5px',
    outline: 0,
    marginBottom: '20px',
    backgroundColor: '#FFCD00'
  },
  formBusiness: {
    width: '100%'
  },

  logo: {
    width: '200px',
    height: '100px',
    marginBottom: '30px'
  },
  Internationalization: {
    display: 'inline',
    marginLeft: '70%',
    margin: theme.spacing(0, 0),

    '& img': {
      paddingRight: '5px',
      width: '32px',

      cursor: 'pointer'
    }
  }
}));
