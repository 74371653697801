/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import { makeStyles } from '@material-ui/core/styles';
import { Button, Divider } from '@material-ui/core';

const useStyles = makeStyles(theme => ({
  buttonExpand: {
    width: '80px',
    height: '30px'
  },
  root: {
    display: 'flex',
    padding: 10
  },
  folders: {
    height: 216,
    flexGrow: 1,
    maxWidth: 300
  },
  buttonCancel: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#F53623',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#A63623',
      opacity: '10'
    }
  },
  buttonSave: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#FFCD00',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#FFCD50',
      opacity: '10'
    }
  },
  form: {
    backgroundColor: '#fff',
    width: '400px',

    borderRadius: '6px',

    '& h3': {
      textAlign: 'center',
      marginBottom: '10px'
    },

    '& button': {
      cursor: 'pointer',
      height: '36px',
      margin: theme.spacing(1, 0, 1),
      backgroundColor: '#FFCD00',
      color: '#F0F0F0',
      borderRadius: '5px',
      border: `1px solid #F0F0F0`,
      outline: 0,
      '&:hover': {
        backgroundColor: '#FFCD00',
        opacity: '10'
      }
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',

      '& input': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #F0F0F0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginBottom: '15px',
        backgroundColor: '#fff',

        '&:focus': {
          border: `1px solid #FFCD00`,
          color: '#000'
        }
      }
    }
  },
  list: {
    display: 'flex',

    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  },
  reportItem: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  },

  reportItemTemplate: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& a': {
      display: 'inline-block'
    },

    '& svg': {
      fontSize: '16px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  }
}));

const AuxilioRight = () => {
  const { studio, auth } = useSelector(state => state);
  const classes = useStyles();
  const [stateColor, setStateColor] = useState('#fff');

  return (
    <>
      <Divider style={{ marginTop: 5, marginBottom: 20 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <Button
          variant="outlined"
          color="primary"
          onClick={() => window.open('https://fonts.google.com/', '_blank')}
        >
          Google Fonts
        </Button>
      </div>
      <Divider style={{ marginTop: 10 }} />
      <div
        style={{
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center',
          width: 380,
          marginTop: 20,
          marginLeft: 20
        }}
      >
        <SketchPicker
          width="360"
          color={stateColor}
          onChange={color => setStateColor(color.hex)}
        />
      </div>
    </>
  );
};

export default AuxilioRight;
