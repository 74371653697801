import React, { useEffect } from 'react';
import DataGrid, {
  Column,
  Editing,
  FilterRow,
  Selection,
  Scrolling,
  Summary,
  TotalItem,
  Popup,
  Position,
  Form
} from 'devextreme-react/data-grid';

import { Item } from 'devextreme-react/form';
import { useDispatch, useSelector } from 'react-redux';
import { Button } from '@material-ui/core';
import './styles.css';
import { getTerms, postTerms } from 'store/modules/localization/actions';
import { Loading } from 'componentes';
import { toast } from 'react-toastify';
import { exportCSVFile } from 'utils/exportCsv';

const headers = {
  context: 'Context',
  type: 'Type',
  term: 'Term',
  updated: 'Updated',
  enUs: 'en-US',
  esEs: 'es-ES',
  ptBr: 'pt-BR'
};

const Translate = () => {
  const datagrid = React.createRef();
  const dispatch = useDispatch();
  const { localization, auth } = useSelector(state => state);

  async function loadData() {
    dispatch(getTerms(`${auth.apiPath}/safe/localization/terms`));
  }
  useEffect(() => {
    loadData();
  }, []);

  const onExport = () => {
    const rows = datagrid.current.instance.getSelectedRowsData();
    exportCSVFile(headers, rows, 'const-translate');
  };

  const onRowInserting = e => {
    dispatch(
      postTerms(`${auth.apiPath}/safe/localization/terms`, {
        terms: [e.data]
      })
    );

    toast.success('Inserting Success');

    setTimeout(() => {
      loadData();
    }, 300);
  };

  const onRowUpdating = e => {
    const newTerm = { ...e.key, ...e.newData };
    dispatch(
      postTerms(`${auth.apiPath}/safe/localization/terms`, {
        terms: [newTerm]
      })
    );

    toast.success('Updating Success');

    setTimeout(() => {
      loadData();
    }, 300);
  };

  if (!localization.terms) {
    return <Loading />;
  }

  return (
    <>
      <div className="divContainer">
        <Button variant="outlined" id="btnExport" onClick={onExport}>
          Export
        </Button>
      </div>

      <DataGrid
        id="gridTransalte"
        ref={datagrid}
        dataSource={localization.terms.lines}
        allowColumnResizing
        columnMinWidth={100}
        onRowInserting={onRowInserting}
        onRowUpdating={onRowUpdating}
      >
        <Selection mode="multiple" />
        <Scrolling mode="infinite" />
        <FilterRow visible applyFilter="auto" />
        <Editing mode="popup" allowUpdating allowAdding useIcons>
          <Popup title="Context" showTitle width={800} height={420}>
            <Position my="center" at="center" of={window} />
          </Popup>
          <Form>
            <Item itemType="group" colCount={3} colSpan={2}>
              <Item dataField="context" isRequired />
              <Item dataField="type" isRequired />
              <Item dataField="term" isRequired />
            </Item>
            <Item itemType="group" caption="Translate" colCount={1} colSpan={2}>
              <Item dataField="enUs" isRequired />
              <Item dataField="esEs" isRequired />
              <Item dataField="ptBr" isRequired />
            </Item>
          </Form>
        </Editing>

        <Column dataField="context" />
        <Column dataField="type" />
        <Column dataField="term" />
        <Column dataField="updated" dataType="datetime" />
        <Column dataField="enUs" caption="en-US" />
        <Column dataField="esEs" caption="es-ES" />
        <Column dataField="ptBr" caption="pt-BR" />

        <Summary>
          <TotalItem column="context" summaryType="count" />
        </Summary>
      </DataGrid>
    </>
  );
};

export default Translate;
