import styled, { css } from 'styled-components';

export const Theme = styled.div`
  ${props =>
    props.theme &&
    css`
      background-color: #fff !important;

      .dx-freespace-row {
        background-color: #fff !important;
      }
      .dx-datagrid-headers {
        color: #fff !important;
        background-color: #808080 !important;
      }

      .dx-datagrid-summary-item {
        color: #9c9c9c !important;
        text-align: left !important;
        margin-top: -3px !important;
      }

      .dx-datagrid-total-footer {
        display: flex;
        justify-content: center;
        background-color: rgba(224, 224, 224) !important;

        height: 35px;
      }
      //cor nao
      .dx-datagrid {
        background-color: #fff !important;
        color: #000 !important;
      }
      //cor sim
      .dx-datagrid .dx-row-alt > td,
      .dx-datagrid .dx-row-alt > tr > td {
        background-color: #f5f5f5 !important;
      }
      .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > td,
      .dx-datagrid-rowsview .dx-selection.dx-row:not(.dx-row-focused) > tr > td,
      .dx-datagrid-rowsview
        .dx-selection.dx-row:not(.dx-row-focused):hover
        > td,
      .dx-datagrid-rowsview
        .dx-selection.dx-row:not(.dx-row-focused):hover
        > tr
        > td {
        color: #fff !important;
        background-color: #009fe3 !important;
      }

      .react-tabs__tab {
        background-color: #fff !important;
        color: #009fe3 !important;
        font-size: 12px !important;
      }
      .react-tabs__tab--selected {
        border-bottom: 1px solid #009fe3 !important;
        background-color: #009fe3 !important;
        color: #fff !important;
        font-size: 12px !important;
      }

      .dx-datagrid {
        font-size: 12px !important;
      }
    `}
`;
