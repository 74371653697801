/* eslint-disable no-nested-ternary */
/* eslint-disable jsx-a11y/click-events-have-key-events */
/* eslint-disable jsx-a11y/no-static-element-interactions */
/* eslint-disable react/button-has-type */
/* eslint-disable jsx-a11y/anchor-is-valid */
import React, { useEffect, useState } from 'react';
import { useSelector, useDispatch } from 'react-redux';
import { SketchPicker } from 'react-color';
import { Tab, Tabs, TabList, TabPanel } from 'react-tabs';
import { makeStyles } from '@material-ui/core/styles';
import { TreeView, TreeItem } from '@material-ui/lab';
import { CircularProgress, Divider } from '@material-ui/core';
import ArrowDropDownIcon from '@material-ui/icons/ArrowDropDown';
import ArrowRightIcon from '@material-ui/icons/ArrowRight';
import { BsTable } from 'react-icons/bs';
import DragIndicatorIcon from '@material-ui/icons/DragIndicator';
import FolderIcon from '@material-ui/icons/Folder';
import AccountTreeIcon from '@material-ui/icons/AccountTree';
import TableChartIcon from '@material-ui/icons/TableChart';
import AllInboxIcon from '@material-ui/icons/AllInbox';
import ViewWeekIcon from '@material-ui/icons/ViewWeek';
import VpnKeyIcon from '@material-ui/icons/VpnKey';
import ListIcon from '@material-ui/icons/List';
import DetailsIcon from '@material-ui/icons/Details';
import FunctionsIcon from '@material-ui/icons/Functions';
import { getDatabase } from 'store/modules/studio/actions';
import ToolTip from '../Toolpit';

const useStyles = makeStyles(theme => ({
  buttonExpand: {
    width: '80px',
    height: '30px'
  },
  root: {
    display: 'flex',
    padding: 10
  },
  folders: {
    height: 216,
    flexGrow: 1,
    maxWidth: 300
  },
  buttonCancel: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#F53623',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#A63623',
      opacity: '10'
    }
  },
  buttonSave: {
    cursor: 'pointer',
    height: '36px',
    width: '100px',
    margin: theme.spacing(1, 0, 1),
    backgroundColor: '#FFCD00',
    color: '#F0F0F0',
    borderRadius: '5px',
    border: `1px solid #F0F0F0`,
    outline: 0,
    '&:hover': {
      backgroundColor: '#FFCD50',
      opacity: '10'
    }
  },
  form: {
    backgroundColor: '#fff',
    width: '400px',

    borderRadius: '6px',

    '& h3': {
      textAlign: 'center',
      marginBottom: '10px'
    },

    '& button': {
      cursor: 'pointer',
      height: '36px',
      margin: theme.spacing(1, 0, 1),
      backgroundColor: '#FFCD00',
      color: '#F0F0F0',
      borderRadius: '5px',
      border: `1px solid #F0F0F0`,
      outline: 0,
      '&:hover': {
        backgroundColor: '#FFCD00',
        opacity: '10'
      }
    },

    '& form': {
      display: 'flex',
      flexDirection: 'column',

      '& input': {
        width: '100%',
        height: '36px',
        fontSize: '14px',
        border: `1px solid #F0F0F0`,
        borderRadius: '5px',
        outline: 0,
        transition: 'all ease .4s',
        marginBottom: '15px',
        backgroundColor: '#fff',

        '&:focus': {
          border: `1px solid #FFCD00`,
          color: '#000'
        }
      }
    }
  },
  list: {
    display: 'flex',

    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  },
  reportItem: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& svg': {
      fontSize: '14px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  },

  reportItemTemplate: {
    display: 'flex',
    alignContent: 'center',
    marginTop: 5,
    width: '100%',
    transition: 'all 2s ease',
    '&:hover': {
      '& span': {
        marginLeft: 'auto',
        display: 'flex'
      }
    },

    '& a': {
      display: 'inline-block'
    },

    '& svg': {
      fontSize: '16px'
    },

    '& span': {
      display: 'none',
      marginLeft: 'auto'
    },

    '& p': {
      marginLeft: '3px',
      marginTop: '-3px'
    }
  }
}));

const TablesExplores = () => {
  const { studio, auth } = useSelector(state => state);
  const classes = useStyles();
  const dispatch = useDispatch();
  useEffect(() => {
    dispatch(getDatabase(`${auth.apiPath}/safe/reports/database`));
  }, []);

  const renderTree = e => {
    return (
      <TreeItem
        style={{ padding: 5 }}
        nodeId={`${e.id}1`}
        label={
          <div style={{ display: 'flex', alignItems: 'center' }}>
            <a className={classes.reportItemTemplate}>
              {e.type === 'schema' ? (
                <AccountTreeIcon />
              ) : e.type === 'tables' ? (
                <TableChartIcon />
              ) : e.type === 'folder' ? (
                <BsTable />
              ) : e.type === 'column' ? (
                <DragIndicatorIcon />
              ) : e.type === 'constraint' ? (
                <VpnKeyIcon />
              ) : e.type === 'index' ? (
                <ListIcon />
              ) : e.type === 'trigger' ? (
                <DetailsIcon />
              ) : e.type === 'function' ? (
                <FunctionsIcon />
              ) : (
                <AllInboxIcon />
              )}

              <ToolTip title={e.name} placement="left">
                <p>{e.name}</p>
              </ToolTip>
            </a>
          </div>
        }
      >
        {e.items && e.items.map(s => renderTree(s))}
      </TreeItem>
    );
  };

  if (!studio.database) {
    return (
      <div
        style={{
          marginTop: 50,
          display: 'flex',
          justifyContent: 'center',
          alignItems: 'center'
        }}
      >
        <CircularProgress style={{ color: '#009fe3' }} size={40} />
      </div>
    );
  }

  return (
    <>
      <Divider style={{ marginTop: 5, marginBottom: 20 }} />

      <TreeView
        style={{
          height: '38vh',
          flexGrow: 1,
          maxWidth: 400
        }}
        defaultCollapseIcon={<ArrowDropDownIcon />}
        defaultExpandIcon={<ArrowRightIcon />}
        defaultEndIcon={<div style={{ width: 24 }} />}
      >
        <TreeItem
          style={{ padding: 5 }}
          nodeId={0}
          label={
            <div style={{ display: 'flex', alignItems: 'center' }}>
              <a className={classes.reportItemTemplate}>
                <FolderIcon color="inherit" style={{ marginRight: 5 }} />

                <p> Root </p>
                <span style={{ height: 20, marginTop: -5 }} />
              </a>
            </div>
          }
        >
          {studio.database.lines[0].get_database_tree.map(v => renderTree(v))}
        </TreeItem>
      </TreeView>
    </>
  );
};

export default TablesExplores;
