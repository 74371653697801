/* eslint-disable no-param-reassign */
import produce from 'immer';
import {
  SIGN_IN_REQUEST,
  SIGN_IN_SUCCESS,
  SIGN_FAILURE,
  SIGN_OUT
} from '../types';

const INITIAL_STATE = {
  token: null,
  open: false,
  signed: false,
  error: false,
  loading: false,
  role: '',
  username: '',
  apiPath:
    window.location.hostname === 'localhost'
      ? process.env.REACT_APP_BASE_URL
      : `${window.location.origin}/api/v1`
};

const auth = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case SIGN_IN_REQUEST: {
        draft.signed = false;
        draft.loading = true;
        break;
      }
      case SIGN_IN_SUCCESS: {
        draft.token = action.payload.token;
        draft.unit = action.payload.unit;
        draft.language = action.payload.language;
        draft.signed = true;
        draft.error = false;
        draft.role = action.payload.decodedToken.perfil;
        draft.loading = false;
        draft.username = action.payload.decodedToken.name;

        break;
      }
      case SIGN_FAILURE: {
        draft.signed = false;
        draft.error = true;
        draft.loading = false;
        break;
      }
      case SIGN_OUT: {
        draft.token = null;
        draft.signed = false;
        break;
      }

      default:
        break;
    }
  });
};

export default auth;
