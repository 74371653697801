/* eslint-disable no-param-reassign */
import produce from 'immer';
import * as actionTypes from '../types';

const INITIAL_STATE = {
  type: 'product',
  normalized: 'all',
  businessUnit: [],
  dataType: null,
  dataMain: null,
  dataNorm: null,
  dataUnit: null,
  typePost: null,
  mainPost: null,
  normPost: null,
  dataNormFiltred: null,
  mainPut: null,

  clickOkSearch: false
};

const getType = (state, action) => {
  return {
    ...state,
    dataType: action.data
  };
};

const getDataUnit = (state, action) => {
  return {
    ...state,
    dataUnit: action.data
  };
};

const getMain = (state, action) => {
  return {
    ...state,
    dataMain: action.data
  };
};

const getNorm = (state, action) => {
  return {
    ...state,
    dataNorm: action.data
  };
};

const getNormFilter = (state, action) => {
  return {
    ...state,
    dataNormFiltred: action.data
  };
};

const postType = (state, action) => {
  return {
    ...state,
    typePost: action.response
  };
};

const postMain = (state, action) => {
  return {
    ...state,
    mainPost: action.response
  };
};

const putMain = (state, action) => {
  return {
    ...state,
    mainPut: action.response
  };
};

const postNorm = (state, action) => {
  return {
    ...state,
    normPost: action.response
  };
};

const setBusinessUnit = (state, action) => {
  return {
    ...state,
    businessUnit: action.data
  };
};

const setClickOkSearch = (state, action) => {
  return {
    ...state,
    clickOkSearch: action.data
  };
};

const normalizer = (state = INITIAL_STATE, action) => {
  return produce(state, draft => {
    switch (action.type) {
      case actionTypes.SET_TYPE: {
        draft.type = action.payload.value;
        break;
      }
      case actionTypes.SET_NORMALIZED: {
        draft.normalized = action.payload.value;
        break;
      }
      case actionTypes.UPDATE_DATA_GRID: {
        action.payload.data.forEach(data => {
          const index = draft.dataNorm.lines.findIndex(
            line => line.idLocal === data.idLocal
          );
          if (index >= 0) {
            draft.dataNorm.lines[index].normalized = action.payload.normalized;
            draft.dataNorm.lines[index].idMain = action.payload.idMain;
            draft.dataNorm.lines[index].translations = action.payload.newValue;
          }
        });

        break;
      }
      case actionTypes.GET_DATA_NORM_MERGED: {
        const ids = new Set(action.payload.merge.map(d => d.idLocal));
        draft.dataNorm.lines = [
          ...action.payload.merge,
          ...action.payload.data.lines.filter(d => !ids.has(d.idLocal))
        ];

        break;
      }

      case actionTypes.GET_DATA_TYPE:
        return getType(state, action);
      case actionTypes.GET_DATA_MAIN:
        return getMain(state, action);
      case actionTypes.GET_DATA_NORM:
        return getNorm(state, action);
      case actionTypes.GET_DATA_NORM_FILTER:
        return getNormFilter(state, action);
      case actionTypes.POST_DATA_TYPE:
        return postType(state, action);
      case actionTypes.POST_DATA_MAIN:
        return postMain(state, action);
      case actionTypes.POST_DATA_NORM:
        return postNorm(state, action);
      case actionTypes.PUT_DATA_MAIN:
        return putMain(state, action);
      case actionTypes.SET_BUSINESS_UNIT:
        return setBusinessUnit(state, action);
      case actionTypes.GET_DATA_UNIT:
        return getDataUnit(state, action);
      case actionTypes.SET_CLICK_OK:
        return setClickOkSearch(state, action);
      default:
        break;
    }
  });
};

export default normalizer;
