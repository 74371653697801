/* eslint-disable jsx-a11y/iframe-has-title */
import React from 'react';
import { useSelector } from 'react-redux';

import './styles.css';

const Catalogue = () => {
  const { token } = useSelector(state => state.auth);

  console.log(document.getElementById('idIframeauth'));

  return (
    <iframe
      id="idIframeauth"
      // src={`http://localhost:3001?auth=${token}`}
      src={`https://dsm.allims.com.br?auth=${token}`}
      width="100%"
      height="100%"
      style={{ border: '5px solid red' }}
    />
  );
};

export default Catalogue;
