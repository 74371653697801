/* eslint-disable import/no-unresolved */
import { createGlobalStyle } from 'styled-components';
import 'react-toastify/dist/ReactToastify.css';

export default createGlobalStyle`

* {
    box-sizing: border-box;
    padding: 0;
    margin: 0;
    outline: 0;
  }
  body, html {
    overscroll-behavior-x: none;
    font-family: 'Fira Sans', Arial, sans-serif;
    text-rendering: optimizeLegibility !important;
    -webkit-font-smoothing: antialiased !important;
    overflow: hidden;
  }

  .dx-datagrid-header-panel .dx-toolbar {
    margin-bottom: 5px !important;
    margin-top: 5px !important;
}
`;
